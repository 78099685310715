@import "../../../../scss/base/var";

$color-timeline: $color-text-light;
$color-horizontal-line: $color-grey;

.selectable {
  &:hover {
    .jobType {
      color: $color-primary;
    }
  }
  &.entry {
    cursor: pointer;
  }
}

.augurBiographyList {
  padding-top: 20px;

  .entries {

    .entry {
      display: flex;
      position: relative;

      .tooltip {
        position: absolute;
        background-color: #fff;
        padding: 10px;
        color: $color-text;
        border-radius: 5px;
        pointer-events: none;
        opacity: 1;
        z-index: 10;
        border: 1px solid $color-grey;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
        white-space: pre-wrap;
        word-wrap: break-word;
      }

      &.selected {
        .jobType {
          color: $color-primary;
        }
      }

      .selectedMarker {
        position: absolute;
        left: 0;
        top: 10px;
        color: $color-primary;
      }

      .timestampContainer {
        width: 160px;
        padding-top: 10px;
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        .timestamp {
          margin-bottom: 10px;
          display: flex;

          .date {
            font-weight: 400;
          }

          .time {
            min-width: 60px;
            display: block;
            text-align: right;
          }
        }

        .ago {
          color: $color-text-light;
          margin-bottom: 15px;
        }
      }

      .lineContainer {
        position: relative;
        width: 20px;

        .line {
          width: 0;
          position: absolute;
          left: 10px;
        }
      }

      .bubble {
        width: 17px;
        height: 17px;
        border-radius: 50%;
        border: 4px solid $color-white;
        position: absolute;
        left: 2px;
        top: 9px;
      }

      .entryBody {
        width: 20px;
        flex-grow: 1;
        flex-shrink: 0;
        padding: 10px;

        .jobTypeContainer {
          margin-bottom: 5px;
          display: flex;
          align-items: center;

          .jobType {
            font-weight: 500;
            display: inline-block;
          }

          .errorIcon {
            color: $color-red;
            margin-left: 5px;
          }
        }

        .info {
          margin-bottom: 5px;
          display: flex;
          white-space: nowrap;

          &.infoPlot {
            display: flex;
            flex-direction: column;
          }

          &.infoPlotParent {
            margin-top: 10px;
          }

          .infoTitle {
            font-weight: 400;
            margin-right: 5px;
            display: flex;
          }

          .infoValue {
            overflow: hidden;
            text-overflow: ellipsis;

            a {
              text-decoration: none;
              color: $color-primary;
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      &.entryError {

        .line {
          top: 25px;
          bottom: 0;
          border-left: 1px solid $color-timeline;
        }

        .bubble {
          background-color: $color-red !important;
        }

        .entryBody {
          border-bottom: 1px solid $color-horizontal-line;
          margin-bottom: 20px;

          .jobType {
            font-weight: 500;
            color: $color-red;
          }

          .info {
            font-weight: 300;
            margin-bottom: 5px;
            color: $color-red;
            display: block;

            .infoValue {
              text-overflow: ellipsis;

              .infoLink {
                margin-top: 5px;
                padding: clear;

                a {
                  cursor: pointer;
                  color: $color-red;
                }
              }

              .infoLinkInner {
                border-left: none;
                color: $color-red;

                &:hover {
                  span {
                    color: $color-red;
                  }
                }
              }
            }
          }
        }
      }

      &.entryLearning {
        .line {
          top: 0;
          height: 13px; // End at the bubble (keep in sync with .bubble -> "top" property)
          border-left: 1px solid $color-timeline;
        }

        .bubble {
          background-color: $color-primary;
        }

        .entryBody {
          border-bottom: 1px solid $color-horizontal-line;
          margin-bottom: 20px;
        }
      }

      &.entryEvaluation {
        .line {
          top: 0;
          bottom: 0;
          border-left: 1px solid $color-timeline;
        }

        .bubble {
          background-color: $color-dark-grey;
        }
      }

      &.entryPrediction {
        .line {
          top: 0;
          bottom: 0;
          border-left: 1px solid $color-timeline;
        }

        .bubble {
          background-color: $color-primary-highlight;
        }
      }


      &.entryGap {
        .line {
          top: 0;
          bottom: 0;
          border-left: 1px dashed $color-dark-grey;
        }

        .entryBody {
          svg {
            color: $color-text;
          }
        }
      }
    }
  }
}
