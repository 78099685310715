@import '../../../../../scss/base/var';

.lightText {
  color: $color-text-light;
  font-size: 14px;
  padding: 0 0 6px 0;
}

.labelContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}