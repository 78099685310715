@import '../../../../scss/base/var';

.header {
  background: $color-primary;
  color: $color-white;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  height: 48px;
}

.logoContainer {
  display: flex;
  align-items: center;
  position: relative;
  height: 48px;
  min-height: 48px;
  min-width: 245px;
  padding-left: 15px;
}

.logo {
  overflow: hidden;
  display: flex;
  height: 100%;
  padding: 6px 0;
  align-items: center;
}

.loadingContainer {
  position: absolute;
  top: 110px;
  left: 0;
  bottom: 0;
  right: 0;
}
