@import '../../../../../scss/base/var.scss';

.fruitObjectDetection {
  padding: 20px 15px 30px 15px;

  @media(max-width: $media-lg) {
    // This is necessary for iPhones to show the full input area on the bottom
    padding-bottom: 70px;
  }
  height: 100%;
  max-height: 100dvh; // https://stackoverflow.com/a/72245072
  position: relative;
  display: flex;
  flex-direction: column;

  .backButtonParent {
    margin-bottom: 20px;
  }

  .headlineBar {
    display: flex;

    .headline {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 20px;
      display: block;
    }

    .buttonLine {
      margin-left: auto;

      button {
        /* Otherwise the padding left of the icon is too large */
        padding: 0 12px 0 4px;
      }
    }
  }

  .body {
    overflow-y: auto;

    .buttonBar {
      display: flex;
      margin-top: 10px;
      margin-bottom: 30px;

      .fileInput {
        display: none;
      }

      .customFileInput {
        border-radius: 4px;
        border: 1px solid $color-grey;
        background-color: $color-very-light-grey;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 36px;
        transition: background 0.1s ease-in-out;
        cursor: pointer;

        span {
          margin-right: 10px;
        }

        svg {
          color: $color-text-grey;
        }

        &:hover {
          background-color: $color-light-grey;
        }
      }

      .detectButton {
        margin-left: 30px;


        &.disabled {
          background-color: $color-text-light !important;
          cursor: not-allowed;
        }
      }
    }

    .imagesContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @media(max-width: $media-lg) {
        // This is necessary for iPhones to show the images below each other
        grid-template-columns: repeat(1, 1fr);
      }

      .imageColumn {
        .imagePreview, .resultImage {
          margin-bottom: 20px;

          .imageHeadline {
            margin-bottom: 10px;
            font-weight: 400;
            font-size: 16px;
            display: block;
          }

          .image {
            max-width: 100%;
            max-height: 400px;
          }
        }

        .error {
          color: red;
          margin-bottom: 20px;
        }
      }
    }
  }
}
