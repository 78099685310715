.ChangePassword {
  margin: 0 0 60px 0;

  &--Field--Parent {
    margin-bottom: 1rem;
  }

  .InputError {
    margin-top: 11px;
    display: block;
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    gap: 8px;
    flex-direction: row;
    justify-content: flex-end;
  }
}
