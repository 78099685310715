.merge-request-tab {
  margin-top: 10px;

  .description {
    margin-bottom: 20px;
  }

  .status-box {
    display: flex;
    flex-direction: column;

    .top {
      margin-bottom: 15px;
    }

    .branch-name {
      color: $color-primary;
      font-weight: bold;
    }
  }

  .conflict-parent {
    width: 100%;
   display: flex;
  }

  .conflict-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: auto;
    margin-top: auto;

    div {
      margin-bottom: auto;
      margin-top: auto;
    }

    .button {
      background-color: $color-green;
    }
  }

  .error-icon {
    color: $color-red;
  }

  .checked-icon {
    color: $color-green;
  }

  .checked,
  .error {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      margin-left: 5px;
    }
  }

  .error {
    color: $color-red;
  }

  &.merge-request-resolve {

    .conflicted-files-parent {
      &.renamed-files-parent,
      &.resolved-files-parent{
        margin-top: 40px;
      }

      .conflicted-files-headline {
        font-weight: 400;
        margin-bottom: 5px;
      }

      .conflicted-files-list {
        .conflicted-file {
          display: flex;
          align-items: center;
          padding: 10px;
          cursor: pointer;

          .conflicted-file-icon-parent {
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .resolved-files-button-bar{
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.resolve-conflicts-container {

  .resolve-conflicts-headline {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 400;
  }

  .conflicts-parent {
    margin-top: 20px;

    .conflicts-row {
      // border: 1px solid purple;
      display: flex;
      flex-direction: row;

      &:not(:last-child){
        // border-bottom: 1px solid $color-grey;
      }

      &.conflicts-row-header{
        font-weight: 500;
        border-bottom: 1px solid $color-grey;
      }

      &.conflicts-row-buttons {
        border-bottom: 1px solid $color-grey;

        .buttons-column {

          .buttons-base-file{
            display: flex;
          }

          .merger-button{
            width: 30px;
            height: 30px;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $color-text;
            margin-right: 5px;

            &:hover{
              background-color: $color-grey;
              color: $color-white;
            }
            &.disabled{
              color: $color-grey;
              &:hover{
                background-color: $color-white;
                color: $color-grey;
              }
            }
          }
        }
      }

      .conflicts-column {
        padding: 5px 0 5px 5px;
        width: 100px;
        flex-grow: 1;
        transition: background-color 0.25s ease-in-out;

        .ace_active-line{
          background-color: transparent !important;
        }

        &.base-file {
          border-left: 1px solid $color-grey;
          border-right: 1px solid $color-grey;
        }

        &.source-file {
          border-left: 1px solid $color-grey;
        }

        &.target-file {
          border-right: 1px solid $color-grey;
        }

        &.collapsed { // Keep a fixed width of 40px for the button to expand the collapsed column
          flex-grow: 0;
          width: 40px;
        }

        &.single-diff-container{
          display: flex;

          &:not(.diff-handled){
            &.add {
              background-color: $color-green-light;
            }
            &.change {
              background-color: $color-orange-light;
            }
            &.remove {
              background-color: $color-red-light;
            }
          }

          &.diff-handled{
            background-color: $color-very-light-grey;
          }

          .actions-parent {
            width: 30px;
            flex-grow: 0;
            // border: 1px solid lightsalmon; // dev only
            padding: 10px 5px;
            display: flex;
            // justify-content: center;
            flex-direction: column;
          }

          .diff-action{
            .diff-action-icon{
              cursor: pointer;

              &.add {
                color: $color-green-dark;
              }
              &.change {
                color: $color-orange-dark;
              }
              &.remove {
                color: $color-red-dark;
              }
              &.reject {
                color: $color-text-light;
              }
            }
          }

          .conflicts-notebook {
            flex-grow: 1;
          }
        }
      }
    }
  }

  .amount-open-diffs{
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;

    .conflicts-open{
      color: $color-red;
    }
  }

  .resolve-conflicts-buttons {
    margin-top: 20px;
    display: flex;
    gap: 5px;
    flex-direction: row;
    justify-content: flex-end;
  }
}
