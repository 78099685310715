@import '../../../../../../../../scss/base/var';
.pagination{
  position: sticky ;
  top: 0 ;
  z-index: 10 ;
  background-color: #fff ;
}
.antTable * th {
  background-color: transparent !important;
  background: transparent !important;
  padding: 0.5rem 0.625rem 0.625rem !important;
  border-radius: 0 !important;
  color: #a6aeb5 !important;
  font-weight: 300 !important;

  font-size: 12px;
  line-height: 14.4px !important;
  border-bottom: none !important;

  &::before {
    width: 0 !important;
  }
}

.antTable * td {
  padding: 0.5rem 0.625rem 0.625rem !important;
  border-top: 1px #dce2eb solid !important;
  border-bottom: none !important;
  font-size: 12px;
  line-height: 14.4px !important;
}

.antTable {
  border-radius: 4px;
  & * a {
    text-decoration: none !important;

    color: #a6aeb5 !important;

    & * span {
      color: #a6aeb5 !important;
    }
  }
  thead{
    position: sticky;
    top: 32px;
    z-index: 10;
    background-color: #fff;
  }
}

.antTableStriped {
  border-left: none !important;
  border-right: none !important;

  & tbody tr:nth-child(2n + 1) {
    background-color: #eef0f2;
  }
  border-radius: 0;
}
