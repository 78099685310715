@import '../../../../../scss/base/var.scss';

.placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;

    .iconContainer {
      margin-bottom: 10px;
      background-color: $color-blue;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .title {
      // color: $color-text-grey;
      font-weight: 500;
      font-size: 18px;
    }
  }
}
