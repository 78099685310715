
.container {
  display: flex;
  flex: 1;
  min-height: 0;
}

.content {
  height: 100%;
  flex-basis: 80%;
  display: flex;
  flex-direction: column;
}

.flowContainer {
  flex-grow: 1;
}

.bottomBar {
  min-height: 300px;
  border-top: solid #dce2eb 1px;
}

.sideBar {
  flex-basis: 20%;
  min-width: 330px;
  padding: 16px;
  border-left: solid #dce2eb 1px;
  display: flex;
  flex-direction: column;
}

.codeContainer {
  margin-top: 8px;
  border: solid #dce2eb 1px;
  border-radius: 4px;
  padding: 4px;
  overflow: auto;
  flex: 1;
}

.sidebarHeader {
  display: flex;
  gap: 4px;
}

.clipboard {
  cursor: pointer;
}
