@import '../../../../../scss/base/var';

.buttonBar {
  display: flex;
  border-bottom: 1px solid $color-grey;
  background-color: $color-white;
  height: 41px;
  align-items: center;
  padding: 4px;
  gap: 2px;
}

.buttonContainer {
  position: relative;
}
