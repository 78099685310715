@import '../styles.module';

.schedule {
  grid-template-columns: 2fr 1fr 200px 160px 40px 90px;
}

.grid1_2 {
  grid-row: '1';
  grid-column: '2';
}
.grid1_1 {
  grid-row: '1';
  grid-column: '1';
}
.grid1_3 {
  grid-row: '1';
  grid-column: '3';
}
.grid1_4 {
  grid-row: '1';
  grid-column: '4';
}
.grid1_5 {
  grid-row: '1';
  grid-column: '5';
}

.grid1_6 {
  grid-row: '1';
  grid-column: '6';
}
