@import '../../../../../scss/base/var';

.parentContainer {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
  height: 100%;

  .headerContainer {
    display: flex;
    flex-direction: row;
    min-height: 18px;
    margin: 0 0 12px 0;

    .title {
      color: $color-text;
      font-weight: 400;
      font-size: 15px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .infoIcon {
      min-width: 17px;
      margin-left: 10px;
      text-align: center;
      cursor: pointer;
    }

    .fullScreen {
      display: flex;
      margin-left: auto;
    }
  }

  .content {
    flex-grow: 1;
    min-height: 0;
    min-width: 0;
    overflow: auto;

    .invalid {
      color: red;
    }
  }
}
