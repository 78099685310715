@import '../../../../../scss/base/var.scss';

.graphSearch {
  padding: 20px 15px 30px 15px;

  @media(max-width: $media-lg) {
    // This is necessary for iPhones to show the full input area on the bottom
    padding-bottom: 70px;
  }
  height: 100%;
  max-height: 100dvh; // https://stackoverflow.com/a/72245072
  position: relative;
  display: flex;
  flex-direction: column;

  .backButtonParent {
    margin-bottom: 20px;
  }

  .headlineBar {
    display: flex;

    .headline {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 20px;
      display: block;
    }

    .buttonLine {
      margin-left: auto;

      button {
        /* Otherwise the padding left of the icon is too large */
        padding: 0 12px 0 4px;
      }
    }
  }
}

.chatContainer {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.outputContainer {
  margin-bottom: 10px;
  height: 110px;
  display: flex;
  flex-grow: 1;
}

.inputContainerSpeech {
  margin-bottom: 5px;

  .s2tAugurInfo {
    font-size: 12px;
    text-align: right;
    display: block;
    margin-right: 20px;
    margin-bottom: 2px;
    color: $color-text-light;
  }
}

.inputContainer {
  position: relative;
  display: flex;
  justify-content: center;
  height: 48px;
  border-radius: 24px;
  border: 1px solid $color-grey;
  background-color: white;

  .textInputContainer {
    flex: 1;
    width: 1px;
    display: flex;
    flex-direction: row;
    margin-left: 5px;
    margin-top: 2px;
    margin-bottom: 2px;
    overflow: hidden;

    .textInput {
      border: none;
      background: none;
      height: 40px;

      &::placeholder {
        color: $color-text-light;
      }

      @media(max-width: $media-lg) {
        // This is necessary for iPhones so that there won't be zooming into the text field
        font-size: 16px;
      }
    }
  }

  .submitButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: $color-light-grey;
    transition: background-color ease-in-out 0.1s;
    &:hover {
      background-color: $color-grey;
    }

    svg {
      color: $color-text-grey;
    }
  }
}

.infoContainer {
  margin: 5px 10px 0 10px;
  text-align: center;
  color: $color-text-grey;
  font-size: 12px;

  span {
    display: block;
  }
}
