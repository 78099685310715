.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.projectCardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
  margin: 10px 0;
}

.projectCardHeader {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
}

.projectCardTitle {
  margin: 0 4px;
}

.noElements {
  height: 40px;
  margin: 11px 0; // 10px + 1px for border
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
}
