.footer {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.field {
  margin-bottom: 1rem;
}

.saveButton {
  display: inline-block;
  margin-left: auto;
}
