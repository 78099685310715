@use 'theme.module';

// TODO only temporary until var.scss is turned into a css-module
/* Colors  */
$color-blue: #224e90;
$color-blue-highlight: #6aa0eb;
$color-grey: #dce2eb;
$color-blue-grey: #eef2f6;
$color-black: #3a3a3a;
$color-dark-grey: #9f9f9f;
$color-red: #ec3d3d;
$color-red-light: #f4e9e9;
$color-red-dark: #c23030;
$color-green: #9fd356;
$color-green-light: #eef4df;
$color-green-dark: #5ea400;
$color-link: #3971b2;
$color-orange: #f2ad5f;
$color-orange-light: #f9e3b5;
$color-orange-dark: #f38a12;
$color-yellow: #f2dc22;
$color-yellow-light: #f5edb6;
$color-lime: #c2d35b;
$color-purple: #8c2290;
$color-health-no: #6f7b86;
$color-health-4: #6b9b28;
$color-health-3: #8d9e2a;
$color-health-2: #a4940a;
$color-health-1: #d97c12;
$color-health-0: #ec3d3d;

$color-light-grey: #eef0f2;
$color-very-light-grey: #f5f7f9;
$color-white: #fff;

$color-text: #071b32;
$color-text-grey: #394d63;
$color-text-light: #a6aeb5;

// Orchestration colors
$col-status-grey: #78909c;
$col-status-green: #388e3c;
$col-status-orange: $color-orange;
$col-status-red: #c62828;
$col-status-blue: $color-blue;
$col-status-purple: #7b1fa2;

// AugurLayoutElements
$color-nodata-fill: #a0a7af11;
$color-nodata-stroke: #a0a7af55;
$color-nodata-text: #a0a7af;

:export {
  colorPrimary: theme.$color-primary;
  colorPrimaryLighter30: theme.$color-primary-lighter30;
  colorPrimaryLighter60: theme.$color-primary-lighter60;
  colorPrimaryLighter75: theme.$color-primary-lighter75;
  colorPrimaryLighter90: theme.$color-primary-lighter90;
  colorPrimaryLighter99: theme.$color-primary-lighter99;
  colorPrimaryDarker30: theme.$color-primary-darker30;
  colorPrimaryDarker60: theme.$color-primary-darker60;
  colorPrimaryDarker75: theme.$color-primary-darker75;
  colorPrimaryDarker90: theme.$color-primary-darker90;
  colorPrimaryTransparent: theme.$color-primary-transparent;
  colorPrimaryHighlight: theme.$color-primary-highlight;
  colorPrimaryHighlightLighter30: theme.$color-primary-highlight-lighter30;
  colorPrimaryHighlightLighter60: theme.$color-primary-highlight-lighter60;
  colorPrimaryHighlightLighter90: theme.$color-primary-highlight-lighter90;
  colorPrimaryHighlightLighter75: theme.$color-primary-highlight-lighter75;
  colorPrimaryHighlightLighter99: theme.$color-primary-highlight-lighter99;
  colorPrimaryHighlightDarker30: theme.$color-primary-highlight-darker30;
  colorPrimaryHighlightDarker60: theme.$color-primary-highlight-darker60;
  colorPrimaryHighlightDarker75: theme.$color-primary-highlight-darker75;
  colorPrimaryHighlightDarker90: theme.$color-primary-highlight-darker90;
  colorPrimaryHighlightTransparent: theme.$color-primary-highlight-transparent;
  colorSecondary: theme.$color-secondary;
  colorSecondaryLighter30: theme.$color-secondary-lighter30;
  colorSecondaryLighter60: theme.$color-secondary-lighter60;
  colorSecondaryLighter75: theme.$color-secondary-lighter75;
  colorSecondaryLighter90: theme.$color-secondary-lighter90;
  colorSecondaryLighter99: theme.$color-secondary-lighter99;
  colorSecondaryDarker30: theme.$color-secondary-darker30;
  colorSecondaryDarker60: theme.$color-secondary-darker60;
  colorSecondaryDarker75: theme.$color-secondary-darker75;
  colorSecondaryDarker90: theme.$color-secondary-darker90;
  colorSecondaryTransparent: theme.$color-secondary-transparent;

  colorPrimaryDefault: theme.$default-color-primary;
  colorPrimaryHighlightDefault: theme.$default-color-primary-highlight;
  colorSecondaryDefault: theme.$default-color-secondary;

  colorTextLight: $color-text-light;
  colorBlue: $color-blue;
  colorBlueHighlight: $color-blue-highlight;
  colorBlueGrey: $color-blue-grey;
  colorGreen: $color-green;
  colorOrange: $color-orange;
  colorWhite: $color-white;
  colorBlack: $color-black;
  colorGrey: $color-grey;
  colorDarkGrey: $color-dark-grey;
  colorRed: $color-red;
  colorHealtNo: $color-health-no;
  colorHealth4: $color-health-4;
  colorHealth3: $color-health-3;
  colorHealth2: $color-health-2;
  colorHealth1: $color-health-1;
  colorHealth0: $color-health-0;

  colorNoDataFill: $color-nodata-fill;
  colorNoDataStroke: $color-nodata-stroke;
  colorNoDataText: $color-nodata-text;
}
