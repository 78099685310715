@import '../../../../../scss/base/var';

.combobox {

  :global(.rw-popup-container) {
    width: 100%;
  }

  .header {
    display: flex;

    .label {
      color: $color-text-light;
      font-size: 14px;
      display: block;
      padding: 0 5px 6px 0;
      white-space: nowrap;
      cursor: default;
    }

    .error {
      margin-left: auto;
      font-size: 14px;
      text-align: right;
      color: $color-red;
      float: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 0 0 5px;
      cursor: default;
    }
  }

  .comboboxInput {
    width: 100%;
    border-radius: 3px;

    &.success {
      border-color: $color-green;
    }

    &.error {
      border-color: $color-red;
    }
  }

  .comboboxSuccess {
    font-size: 18px;
    text-align: right;
    color: $color-green;
    margin: -34px 13px 0 0;
    float: right;
  }
}
