* {
  *, *:before, *:after {
    box-sizing: border-box;
  }
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

strong,
b{
	font-weight: 600;
}

p {
  padding: 0 0 14px 0;
}

a {
  border: none;
  border-width: 0;
  text-decoration: underline;
  color: $color-text;
  &:hover, &:active, &:focus {
    text-decoration: none;
    color: $color-text;
  }
  *, &:active, &:focus {
    outline: none;
    -moz-outline: 0;
  }
}

input, textarea, select {
  color: $color-text;
}

body {
  margin: 0;
  color: $color-text;
  font-family: $Roboto;
  font-size: $size;
  line-height: 1.2;
  font-weight: 300;
  background: $color-light-grey;
}

hr {
  border-bottom-color: #ddd;
}

// TODO remove
.table-reset {
  table {
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%; // From foundation

    thead, tbody, tfoot {
      border-color: $color-grey;
    }

    th, td {
      border-bottom: 1px solid $color-grey;
      border-top: 1px solid $color-grey;
      padding: .5rem .625rem .625rem; // From foundation
      text-align: left; // From foundation
    }

    thead {
      background: $color-white;

      th, td {
        font-size: 12px;
        color: $color-text-light;
        font-weight: 400;
      }
    }

    tbody {
      tr {
        background: $color-light-grey;
      }

      tr {
        &:nth-child(2n) {
          background: $color-white;
        }
      }
    }
  }
}

h1, .h1, h2, .h2 {
  font-weight: 400;
  font-size: 24px;
  color: $color-text;
  font-family: $Roboto;
}
h3, .h3 {
  font-weight: 400;
  font-size: 20px;
  color: $color-text;
  font-family: $Roboto;
}
h4, .h4, h5, .h5, h6, .h6 {
  font-weight: 600;
  font-size: 16px;
  color: $color-text;
  font-family: $Roboto;
}
.link {
  color: $color-link;
  &:hover, &:active, &:focus {
    color: $color-link;
  }
}
.disabled {
  color: $color-text-light;
  cursor: default;
  &:hover, &:active, &:focus {
    text-decoration: underline;
    color: $color-text-light;
  }
}
.small {
  color: $color-text-light;
  font-size: $size;
}
.alert {
  color: $color-red;
  font-size: $size;
  &.flex{
    display: flex;
  }

  &.cursor-pointer {
    cursor: pointer;
  }

  &.hover-underline {
    .message {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.uppercase {
  text-transform: uppercase;
}
.normal {
  font-weight: normal;
}

.tabs {
  border: none;
  background: none;
}
.text-red {
  color: $color-red;
}
.text-green {
  color: $color-green;
}
