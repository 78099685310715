@import '../../../../scss/base/var';

.tabs {
  display: flex;
}

.bubbles {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
