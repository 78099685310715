.container-notebook {
  border-top-right-radius: 4px;

  .notebook-tab-link-container {
    position: relative;
    background-color: $color-white;
    border-bottom: 1px solid $color_grey;
    display: flex;
    height: 41px;
    border-top-right-radius: 4px;
    -ms-overflow-style: none;
    padding-right: 75px;

    &::-webkit-scrollbar {
      display: none;
    }

    .tab-line-drag-overlay {
      transition: all 0.3s ease-in-out;
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: $color-primary-highlight-transparent;
      border: 2px dashed $color-primary-highlight;
    }

    .show-all-button {
      right: 0;
      background-color: $color-grey;
      height: 46px;
      width: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid $color_grey;

      .show-all-icon {
        color: $color-text;
      }

      &:hover,
      &.active {
        background-color: $color-primary;
        cursor: pointer;

        .show-all-icon {
          color: $color-white;
        }
      }
    }

    .overlapping-menu {
      position: absolute;
      display: block;
      top: 47px;
      right: 0;
      z-index: 1;
      border-left: 1px solid $color_grey;
      border-bottom: 1px solid $color_grey;
      background-color: $color-white;

      .overlapping-menu-entry {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: default;
        user-select: none;

        &:hover {
          cursor: pointer;
        }

        .overlapping-icon {
          color: $color-text;
          margin-right: 10px;
          min-width: 20px;
        }

        .overlapping-name {
          margin: 0;
          padding: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }

        &.selected {
          background-color: $color-primary;

          .overlapping-icon {
            color: $color-white;
          }

          .overlapping-name {
            color: $color-white;
          }
        }
      }
    }

    .notebook-tab-links {
      width: 100%;
      display: flex;
      overflow: hidden;
      background-color: $color-white;
      flex-grow: 0;

      .remote-tabs-parent {
        display: flex;
      }

      .notebook-tab-link {
        padding: 0 7px;
        display: flex;
        align-items: center;
        background-color: $color-white;
        height: 40px;
        border: none;
        border-right: 1px solid $color_grey;
        font-size: 14px;
        font-weight: 300;
        font-family: $Roboto;
        color: $color-text;
        cursor: pointer;

        .tab-link-icon {
          color: $color-text;
          margin-right: 7px;
        }

        .notebook-link-name {
          white-space: nowrap;
        }

        .notebook-close-icon {
          color: $color-text;
          margin-left: 7px;
        }

        &.icon-only-tab-link {
          cursor: pointer;
        }

        &.tab-link-grey {
          background-color: $color-light-grey;
        }
      }

      .notebook-tab-link:focus {
        outline: none !important;
      }

      .tab-link-active {
        font-weight: 300 !important;
        background-color: $color-primary;
        color: $color-white;

        .tab-link-icon {
          color: $color-white;
        }

        .notebook-close-icon {
          color: $color-white;
        }
      }

      .loading-container {
        display: flex;
        align-items: center;

        .busy {
          margin: 0 10px;
          fill: $color-blue !important;
        }
      }
    }
  }

  .notebook-tab-content-container {
    width: 100%;
    height: 100%;
    position: relative;

    &:focus {
      outline: none !important;
    }

    div[data-type='SplitPane'] {
      // Styles for the SplitPane parent - unfortunately there is no css class for it
      position: absolute;
      width: 100%;
    }

    .notebook-tab-content {
      background-color: $color-white;
      overflow: hidden;
      height: 100%;
      padding-bottom: 5px; // to introduce the small white line between the notebook content and the bottom border

      &:focus {
        outline: none !important;
      }

      .hotkeys {
        height: 100%;
        display: flex;
        flex-direction: column;

        &:focus {
          outline: none !important;
        }
      }
    }

    .panelGroup {
      .panelWrapper {
        display: block !important; // Otherwise the editors don't gain full width of their split
      }
    }
  }
}

.notebook-content,
.code-content {
  height: 100%;

  .workbench-buttons {
    display: flex;
    border-bottom: 1px solid $color-grey;
    width: 100%;
    padding: 0;

    .workbench-button {
      width: 30px;
      height: 30px;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-text;
      margin: 5px 5px 5px 0;

      &:hover {
        background-color: $color-grey;
        color: $color-white;
      }

      &.disabled {
        color: $color-grey;

        &:hover {
          background-color: $color-white;
          color: $color-grey;
        }
      }

      &.label-button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 30px;
        width: unset; // to overwrite the width from .workbench-button
        padding: 0 15px;
        border-radius: 15px;
        color: $color-white;
        transition: background-color 0.25s ease-out, color 0.25s ease-out;
      }
    }

    .unsaved-changes {
      color: $color-red;
    }

    &:first-child {
      padding-left: 5px;
    }

    .markdown-tab-bar {
      display: flex;
      align-items: center;
      margin-right: 5px;
      margin-left: auto;

      .md-tab {
        color: $color-text;
        background-color: $color-light-grey;
        display: flex;
        align-items: center;
        padding: 0 20px;
        cursor: pointer;
        height: 30px;

        &:first-child {
          border-top-left-radius: 15px;
          border-bottom-left-radius: 15px;
        }

        &:last-child {
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
        }

        &.active {
          color: #fff;
          background: $color-primary;
        }
      }
    }
  }

  .workbench-button-separator {
    margin: auto;
  }

  .info-text-container {
    display: flex;
    align-items: center;
    height: 30px;
    margin: 5px;

    span {
      font-weight: 400;
      font-style: italic;
      cursor: default;
    }

    svg {
      stroke: $color-text-grey;
      margin-right: 5px;
    }
  }
}

.notebook-content {
  display: flex;
  flex-direction: column;

  .hotkeys:focus {
    outline: none;
  }

  .cell-type {
    margin: 5px 5px 5px 0;
    width: 140px;
  }

  .kernel-display-name-container {
    background-color: $color-light-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    padding: 0 7px 0 12px;
    height: 30px;
    border-radius: 2px;
    border: 1px solid $color-grey;
    margin: 5px 15px 5px 0;

    p {
      text-align: right;
      color: $color-text;
      font-weight: 300;
      padding: 0;
      white-space: nowrap;
    }

    .kernel-status {
      width: 24px;
      overflow: hidden;

      .busy {
        svg {
          circle {
            fill: $color-orange;
          }

          circle:not(:first-child) {
            display: none;
          }
        }
      }

      .status-circle {
        width: 16px;
        height: 16px;
        border-radius: 8px;
        margin-left: 7px;

        &.idle {
          background-color: $color-green;
        }

        &.no {
          background-color: $color-text;
        }

        &.starting {
          width: 14px;
          height: 14px;
          border-radius: 7px;
          border: 1px solid $color-text;
        }
      }
    }
  }

  .cells {
    display: block;
    padding-bottom: 200px;
    // height: calc(100vh - (171px + 30px + 50px)); // This is really asking for trouble.
    overflow-y: auto;

    .NotebookCell {
      &:last-child {
        margin-bottom: 250px; // Space after last cell
      }
    }
  }
}

// --- Styles for the notebook view only
.code-cell,
.app-cell-container {
  .output-container-parent {
    border: 1px solid $color-grey;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    background-color: $color-very-light-grey;
    position: relative;

    &.has-error {
      background-color: $color-red-light;
    }

    .output-container-buttons {
      display: flex;
      flex-direction: column;
      width: 22px;
      align-items: center;
      margin: 0 5px;

      .output-button {
        border-radius: 2px;
        width: 20px;
        height: 20px;
        margin-bottom: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $color-grey;
        background-color: $color-white;

        &.output-button-error {
          color: $color-red;
        }

        &.output-button-neutral {
          color: $color-text;
        }
      }
    }

    .output-container-parent--is-overlapping {
      width: 50px;
      height: 20px;
      border-radius: 10px;
      border: 1px solid $color-grey;
      background-color: $color-white;
      position: absolute;
      right: 50%;
      bottom: -10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-primary;
      transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
      cursor: pointer;
      z-index: 2;

      &:hover {
        background-color: $color-primary;
        color: $color-white;
      }
    }

    .output-container-parent--is-overlapping-shadow-top {
      z-index: 1;
      height: 10px;
      background-image: linear-gradient($color-grey, rgba(0, 0, 0, 0));
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    .output-container-parent--is-overlapping-shadow-bottom {
      z-index: 1;
      height: 10px;
      background-image: linear-gradient(rgba(0, 0, 0, 0), $color-grey);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .output-container-parent--scroll-to-bottom {
      border-radius: 2px;
      position: absolute;
      bottom: 0;
      right: 10px;
      z-index: 2;
    }
  }
}

.code-content {
  .editor-parent-container {
    overflow: auto;

    .editor-con {
      margin: 10px;
      border: 1px solid $color-grey;
      border-radius: 2px;
      font-family: $font-monospace !important;
      padding: 5px;

      textarea:focus {
        outline: none !important;
      }
    }

    .markdown-con {
      margin: 10px;
      padding: 5px;
    }
  }
}

.dropdown {
  .Dropdown-root {
    position: relative;
  }

  .Dropdown-control {
    position: relative;
    overflow: hidden;
    background-color: white;
    border: 1px solid $color-grey;
    border-radius: 2px;
    box-sizing: border-box;
    color: $color-text;
    cursor: default;
    outline: none;
    padding: 8px 52px 8px 10px;
    transition: all 200ms ease;
    height: 30px;
    width: 140px;
  }

  .Dropdown-control:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }

  .Dropdown-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: ' ';
    display: block;
    height: 0;
    margin-top: -ceil(2.5);
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0;
  }

  .is-open .Dropdown-arrow {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
  }

  .Dropdown-menu {
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
  }

  .Dropdown-menu .Dropdown-group > .Dropdown-title {
    padding: 8px 10px;
    color: rgba(51, 51, 51, 1);
    font-weight: bold;
    text-transform: capitalize;
  }

  .Dropdown-option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
    display: block;
    padding: 8px 10px;
  }

  .Dropdown-option:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  .Dropdown-option:hover {
    background-color: $color-very-light-grey;
    color: #333;
  }

  .Dropdown-option.is-selected {
    background-color: $color-light-grey;
    color: #333;
  }

  .Dropdown-noresults {
    box-sizing: border-box;
    color: #ccc;
    cursor: default;
    display: block;
    padding: 8px 10px;
  }
}
