@import '../../../../scss/base/var';

.slugContainer {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  align-items: flex-end;

  div {
    flex-grow: 1;
  }

}

.slugInfo {
  flex-grow: 0 !important;
  flex-direction: row;
  height: 46px;
  display: flex;
  align-items: center;

  span {
    margin-right: .25rem;
    font-weight: 400;
  }
}


.image {
  border: 1px solid $color-grey;
  opacity: 1;
  display: block;
  transition: .5s ease;
  backface-visibility: hidden;
  background-color: $color-light-grey;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  text-align: center;
  line-height: 80px;
}

.middle {
  border-radius: 50%;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 40px;
  left: 40px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
}

.container {
  position: relative;
}

.container:hover .image {
  opacity: 0.1;
}

.container:hover .middle {
  opacity: 1;
}

.crossContainer {
  border-radius: 50%;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 40px;
  left: 40px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;

  .cross {
    opacity: 0.8;
    top: 0;
    right: 0;
    padding: 5px 5px 0 0;
    font-size: 20px;
    text-align: right;
    color: $color-red;

    &:hover {
      opacity: 1;
    }
  }
}
