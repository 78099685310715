@import "../../../../scss/base/var";

.contentContainer {
  padding: 20px 15px;
  height: 100%;
  overflow-y: auto;
}

.sessions {
  padding: 20px 15px;
  height: 100%;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

.item {
  margin-top: 12px;
  margin-bottom: 12px;
}

.detailsHeader {
  display: flex;
  align-items: center;
  height: 32px;
}

.backButton {
  flex-grow: 1;
}

.headline {
  font-weight: 400;
  font-size: 15px;
  color: $color-text-light;
}

.headerWithButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.card {
  padding: 10px;
  border: 1px solid $color-grey;
  border-radius: 4px;
  margin-top: 10px;
  overflow-x: auto;
}

.cardGrey {
  background-color: $color-light-grey;
}

.row {
  display: flex;
  cursor: default;
  align-items: center;

  &:hover {
    background-color: $color-blue-grey;
  }
}

.title {
  font-weight: 400;
  margin-right: 10px;
  min-width: 200px;
  padding: 10px;
}

.value {
  padding: 10px;
  overflow-x: auto;
  text-overflow: ellipsis;

  li:not(:last-child) {
    margin-bottom: 5px;
  }

  td {
    padding-bottom: 5px;
    // Override the table defaults, choose same font size as in list etc.
    font-size: 14px;
    border: none;

    &.jobDetailsValueObjectKey {
      padding-right: 5px;
    }
  }
}

.statusValue {
  font-weight: 400;
  color: $color-white;
  height: 18px;
  padding: 0 18px;
  margin: 9px 10px;
  border-radius: 9px;
}

.statusWaiting {
  background-color: $color-grey;
  color: $color-text;
}

.statusWaitingInExecutionQueue {
  background-color: $color-dark-grey;
}

.statusTriggered {
  background-color: $color-purple;
}

.statusRunning {
  background-color: $col-status-blue;
}

.statusSuccess {
  background-color: $color-green;
}

.statusCancelled {
  background-color: $color-orange;
}

.statusFailure {
  background-color: $color-red;
}

.valueNoValue {
  font-style: italic !important;
  color: $color-text-light;
}

.headerButtonContainer {
  display: flex;
  flex-direction: row-reverse;
}

.refreshIconContainer {
  margin-left: 10px;
  height: 40px;

  .refreshIconSpinningParent {
    width: 30px;
    height: 30px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .refreshIconSpinning {
      color: $color-primary;
      margin: 5px;

      -webkit-animation: spin 2s linear infinite;
      -moz-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
    }
  }

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

