@import '../../../../scss/base/var';


.header {
  display: flex;
  gap: 5px;
  padding: 0 0 6px 0;
}

.label {
  color: $color-text-light;
  font-size: 14px;
  display: block;
  white-space: nowrap;
  padding: 0; // overwrite
}

.description {
  color: $color-text-light;
  flex-shrink: 0;
}

.error {
  margin-left: auto;
  font-size: 14px;
  text-align: right;
  color: $color-red;
  float: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input {
  width: 100%;
  border-radius: 3px;
}
