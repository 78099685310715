@import '../../../../../scss/base/var';

.ContentElement {
  display: flex;
  align-items: center;
  margin: 0 5px;
  padding: 5px 5px 5px 7px;
  border-radius: 8px;
  cursor: default;
  user-select: none;

  &--highlighted {
    padding: 3px 8px 3px 18px; // The padding of .ContentElement, but without the 2px border
    border: 2px dashed $color-primary-highlight;
  }

  .context-menu-parent {
    height: 20px;
    width: 20px;
    border-radius: 2px;

    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
    z-index: 2; // Put it above the .context-menu
    transition: background-color 50ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 16px;
      height: 16px;
      color: $color-dark-grey;
      transition: color 50ms ease-in-out;
    }
    &:hover {
      background-color: $color-dark-grey;
      svg {
        color: $color-white;
      }
    }

    &--active {
      z-index: 5; // Put it above the .context-menu
    }
  }

  &--clickable {
    cursor: pointer;
  }

  .ContentElement--icon {
    color: $color-text;
    margin-right: 10px;
    min-width: 18px;
  }
  .ContentElement--name {
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 1px; // Will grow anyway
    flex-grow: 1;
    flex-shrink: 1;
  }
  &.ContentElement--colored {
    background-color: $color-grey;
  }
  .ContentElement--size {
    margin: 0;
    padding: 0 5px 0 5px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    // width: 25%;
    flex-shrink: 0;
    white-space: nowrap;
    text-align: right;
    color: $color-text-light;
  }

  &:hover {
    background: var(--color-primary-lighter90, #e4ecf9);
    .ContentElement--name {
      color: $color-primary;
    }
    .ContentElement--icon {
      color: $color-primary;
    }
  }
  &.ContentElement--file {
    .ContentElement--icon {
      color: $color-primary;
    }
  }

  &.draggedover {
    background-color: $color-grey;
  }
}
