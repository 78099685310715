@import '../../../../../../../scss/base/var';
.JobGroupStep {

  position: relative;

  .topology {
    overflow: auto;
    padding: 10px;
    background-color: $color-light-grey;
    border: 1px solid $color-grey;
    border-radius: 5px;

    .JobGroupTopologyChart {
      margin-bottom: 20px;
    }
  }

  .editorHint {
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: $color-text-light;
    cursor: default;
  }

  .emptyContainer {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.loadingIndicator {
  border: 1px solid $color-grey;
  background-color: $color-light-grey;
  border-radius: 5px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
}