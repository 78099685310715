@import '../../../../../../../scss/base/var';

$color-text-light: #a6aeb5;
$color-text: #3a3b3d;

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.grid {
  display: grid;
  grid-template-columns: 130px auto;
  column-gap: 24px;
  row-gap: 8px;
  overflow-x: auto;
}

.gridContainer {
  flex: 1;
  overflow-y: auto;
}

.header {
  font-weight: bold;
}

.searchBar {
  width: 200px;
}

.featureCountInfo {
  margin: 10px 0;
  color: $color-text-light;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item {
  &.readOnly {
    opacity: 0.7;
  }
}

.rejected {
  display: flex;
  align-items: center;
  justify-content: center;

  &.rejectedHeader {
    justify-content: start;
  }
}

.feature {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 100px;

  &.discarded {
    font-style: italic;
    color: $color-text;
  }
}

.readOnly {
  pointer-events: none;
}

.noFeaturesAvailable {
  font-size: 16px;
  margin-top: 20px;
  color: $color-text-light;
}

.showMoreButton {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  height: 24px;
  bottom: -12px;
  border: 1px solid #dce2eb;
  line-height: 20px;
  border-radius: 12px;
  background: #fff;
  text-decoration: none;
  font-weight: 400;
  color: $color-primary;
  width: 130px;

  &:hover {
    outline: none !important;
    background-color: $color-light-grey;
    pointer-events: all;
    cursor: pointer;
  }

  .buttonIcon {
    margin-left: 5px;
    margin-right: -5px;
  }
}
