.textInputContainer {
  width: 100%;
}
.notebookContainer {
margin-bottom: 20px;
  span{
    color: red;
  }
}
.dialogContainer {
  padding: 0 20px;
  padding-top: 40px;

}