@import "../../../../scss/base/var";

.timeTravelContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.grid {
  display: grid;
  grid-template-columns: [model-timeline] 1rem [content] auto [jobs-timeline] 16px [timestamp] 112px [end];
  grid-auto-rows: 52px;
  row-gap: 4px;
  column-gap: 8px;
  align-items: center;
  justify-items: stretch;
  padding: 8px;
  overflow-y: auto;
  position: relative;
  max-height: calc(100vh - 100px);
  grid-auto-flow: row;
}

.highlightedAboveContainer,
.highlightedBelowContainer {
  display: grid;
  grid-template-columns: [content] auto [jobs-timeline] 16px [timestamp] 112px [end];
  grid-auto-rows: 32px;
  align-items: center;
  padding: 6px;
  z-index: 100;

}

.highlightedAboveContainer {
  position: sticky;
  top: 0;
  border-bottom: 1px solid #e5e7eb;
  box-shadow: 0 0 90px 30px white, 0 0 3px 2px gray;
}

.highlightedBelowContainer {
  position: sticky;
  bottom: 0;
  border-top: 1px solid #e5e7eb;

  box-shadow: 0 0 90px 30px white, 0 0 3px 2px gray;
}

.timeTravelHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  position: sticky;
  top: 0;
  background: white;
  border-top-right-radius: 4px;
}

.title {
  font-size: 15px;
  font-weight: 400;
}

.compareModeSwitch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: white;
}

.compareModeText {
  margin-left: auto;
  margin-right: 10px;
}

.rowContainer {
  display: grid;
  grid-column: content / end;
  grid-template-columns: subgrid;
  padding: 6px 0;
  width: 100%;
  align-items: center;
  justify-items: stretch;
  border-radius: 14px;
  transition: .05s ease-in-out;
  background-color: white;
  cursor: default;
  position: relative;


  &.selectable {
    cursor: pointer;
  }

  &:not(.selectable) > .content {
    color: #bbbbbb;
  }

  &.selected {
    border: 1px solid $color-primary;
  }

  &.selectable:hover {
    .content {
      text-shadow: .5px 0 $color-primary;

    }
  }

  &.highlighted {
    background-color: $color-primary-lighter90;
  }

  &.highlightedOutOfView {
    position: relative;
    background-color: $color-primary-lighter90;
    cursor: pointer;

    &:hover {
      background-color: $color-primary;
      color:white;
    }
  }
}

.content {
  grid-column: content;
  padding-left: 12px;
  font-weight: 300;
}

.jobsIcon {
  grid-column: jobs-timeline;
  height: 100%;
  width: 100%;
  stroke: $color-primary-lighter60;
}

.timestamp {
  grid-column: timestamp;
  padding-right: 8px;
  color: $color-dark-grey;margin-left: auto;
}

.jobConnection {
  grid-column: jobs-timeline;
  width: 1px;
  background: $color-primary-lighter90;
  margin: 44px 0;
  align-self: stretch;
  justify-self: center;
  border-radius: 1px;

  &.highlighted {
    // background: $color-primary;
  }
}

.modelBar {
  writing-mode: vertical-lr;
  width: 16px;
  border-radius: 8px;
  background: lightgray;
  grid-column: model-timeline;
  font-size: 11px;
  font-weight: normal;
  height: 100%;
  padding: 6px 0;
  line-height: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  z-index: 100;

  &.highlighted {
    color: white;
    background: $color-primary;
  }
}

.scrollButton {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 16px;

  &:hover {
    opacity: 1;
    background-color: $color-primary;
  }
}
.tooltip {
  position: absolute;
  background-color: #fff;
  padding: 10px;
  color: $color-text;
  border-radius: 5px;
  pointer-events: none;
  opacity: 1;
  z-index: 150;
  border: 1px solid $color-grey;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

  // Add these properties for multi-line support
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 200px; // Adjust this value as needed
  text-align: center;
}