@import '../../../../scss/base/var';

.projectCard {
  word-wrap: break-word;
  word-break: break-all;
  background-color: $color-light-grey;
  min-width: 300px;
  position: relative;
  border-radius: 10px;
  border: 1px solid $color-grey;
  padding: 10px;
  display: flex;
  cursor: pointer;

  &:hover {
    background: var(--color-primary-lighter90, #e4ecf9);

    .title {
      text-decoration: underline;
    }
  }

  &.isActive {
    background: var(--color-primary-lighter90, #e4ecf9);
    border: 1px solid $color-primary-highlight;
  }

  .iconContainer {
    width: 40px;
    height: 40px;
    overflow: hidden;
    display: flex;
    flex-shrink: 0;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    overflow: auto;

    .title {
      display: block;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .description {
      display: block;
      color: $color-text-light;
      direction: rtl;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}