:where(.css-dev-only-do-not-override-1kuana8).ant-select-outlined:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #bbb;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-select-outlined:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector,
:where(.css-dev-only-do-not-override-1kuana8).ant-select-outlined:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ).ant-select-focused
  .ant-select-selector {
  border-color: #bbb;
  box-shadow: none;
}

.ant-select-focused:where(
    .css-dev-only-do-not-override-1kuana8
  ).ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #bbb;
  box-shadow: none;
  outline: 0;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #eef0f2;
}
