@import "../../../../scss/base/var";

.augurBiography {
  padding: 26px 20px;
  height: 100%;

  .empty,
  .error,
  .loading {
    height: 100%;
  }

  .content {
    margin-top: 10px;
    overflow-y: auto;
  }
}
