:where(.css-dev-only-do-not-override-1kuana8).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  background-color: #ccc;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: none;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #eef0f2;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-btn-primary:not(
    :disabled
  ):not(.ant-btn-disabled):hover {
  color: #fff;
  background: #72a52b;
  border-radius: 4px;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-btn-primary:not(
    :disabled
  ):not(.ant-btn-disabled) {
  color: #fff;
  background: #9fd356;
  border-radius: 4px;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-btn-primary:disabled,
:where(.css-dev-only-do-not-override-1kuana8).ant-btn-primary.ant-btn-disabled {
  cursor: not-allowed;
  border-color: #d9d9d900;
  color: rgba(0, 0, 0, 0.25);
  background: rgba(0, 0, 0, 0.04);
  box-shadow: none;
  border-radius: 4px;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-picker-outlined:hover {
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-picker-outlined:focus {
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-picker-outlined:focus-within {
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  box-shadow: none;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-picker-dropdown
  .ant-picker-header
  button:hover {
  color: #72a52b;
}
