@import '../../../../scss/base/var';

.multiPageWizard {
  display: flex;
  flex-direction: column;
  height: 100%;

  .headlineContainer {
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding: 30px 20px;
    cursor: default;
    border-bottom: 1px solid $color-light-grey;
    position: relative;

    .shadowBodyTop {
      bottom: -6px;
      height: 6px;
      left: 0;
      right: 0;
      position: absolute;
      background: linear-gradient(to bottom, $color-light-grey, transparent);
    }
  }

  .bodyContainer {
    flex-grow: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }

  .footerContainer {
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 60px;
    border-top: 1px solid $color-light-grey;
    position: relative;

    .shadowBodyBottom {
      top: -6px;
      height: 6px;
      left: 0;
      right: 0;
      position: absolute;
      background: linear-gradient(to bottom, transparent, $color-light-grey);
    }

    .buttonsLeft {
      flex-grow: 0;
      display: flex;
      gap: 8px;
      flex-direction: row;
      justify-content: flex-end;
    }

    .infoCenter {
      flex-grow: 1;
      flex-shrink: 1;
      width: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      cursor: default;
      position: relative;

      .pageTitle {
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: center;
      }

      .pageInfo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 6px;

        .pageBubble {
          width: 16px;
          height: 16px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: border 0.1s ease-in-out, background-color 0.3s ease-in-out;
          position: relative;

          &.pageBubbleUpcoming {
            border: 1px solid $color-primary;
            background-color: $color-white;
          }

          &.pageBubbleCurrent {
            border: 1px solid $color-primary;
            background-color: $color-white;

            &::after {
              content: "";
              background-color: $color-primary;
              position: absolute;
              height: 8px;
              width: 8px;
              border-radius: 4px;
              top: 3px;
            }
          }

          &.pageBubbleDone {
            border: 1px solid $color-green;
            background-color: $color-green;

            svg {
              color: $color-white;
            }
          }
        }

        .pageBubbleConnection {
          width: 8px;
          height: 1px;
          background-color: $color-text-light;
        }
      }
    }

    .buttonsRightPlaceholder {
      width: 250px;
    }

    .buttonsRight {
      flex-grow: 0;
      display: flex;
      gap: 8px;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}

.error {
  color: $color-red;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  font-weight: 400;
}

// --- Responsiveness
@media(max-width: $media-md) {
  .multiPageWizard {
    .footerContainer {
      .buttonsRightPlaceholder {
        display: none;
      }

      .buttonsRight {
        .buttonNext {
          margin-left: 0;
        }

        .buttonSubmit {
          margin-left: 0;
        }
      }
    }
  }
}

@media(max-width: $media-sm) {
  .multiPageWizard {
    .footerContainer {
      .infoCenter {
        display: none;
      }

      .buttonsRight {
        margin-left: auto;
      }
    }
  }
}