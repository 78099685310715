@import '../../../../../scss/base/var';

.header {
  display: flex;
}

.label {
  color: $color-text-light;
  font-size: 14px;
  display: block;
  padding: 0 0 6px 0;
  white-space: nowrap;
  cursor: default;
}

.body {
  display: flex;
  align-items: center;
}

.description {
  margin-right: 10px;
}
