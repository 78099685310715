@import '../../../../scss/base/var';

.projectLink {
  word-wrap: break-word;
  word-break: break-all;
  background-color: $color-white;
  position: relative;
  border-radius: 10px;
  border: 1px solid $color-grey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &:hover {
    background: var(--color-primary-lighter90, #e4ecf9);
  }

  .iconContainer {
    margin: 10px;
    width: 32px;
    height: 32px;
    display: flex;
    flex-shrink: 0;
  }

  .textContainer {
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    margin-right: 10px;

    .title {
      font-weight: 500;
      color: $color-text;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .description {
      color: $color-text-light;
    }
  }

  .noActiveProject {
    font-style: italic;
  }
}