.CheckBoxContainer {
  display: flex;
  width: fit-content;
  padding: 2px 0;
  align-items: center;
  cursor: pointer;

  &.disabled {
    opacity: .4;
    cursor: not-allowed !important;
    text-decoration: none;
  }

  .CheckboxLabel {
    display: flex;
    white-space: nowrap;
    font-weight: 400;
  }

  .Checkbox {
    display: flex;
    height: 20px;
    line-height: 20px;
    padding-right: 10px;

    &.disabled {
      opacity: .4;
      cursor: not-allowed !important;
      text-decoration: none;
    }

    .CheckboxMarkInput {
      //display: none;
      opacity: 0;
      height: 0;
      width: 0;
    }

    .CheckboxMarkInput:checked ~ .CheckboxMark:after {
      display: block;
    }


    .CheckboxMark {
      display: flex;
      height: 20px;
      width: 20px;
      background-color: #fff;
      border: 2px solid #eee;
      border-radius: 4px;

      &:hover:not(.disabled) {
        background-color: #ccc;
      }

      &:after {
        content: '';
        display: none;
        width: 8px;
        height: 14px;
        position: relative;
        right: -4px;
        border: solid darkblue;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }


  }

}
