@import '../../../../../../../scss/base/var';

.container {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: $color-white;
  gap: 20px;
}

.entry {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 20px;
}

.checkbox {

}

.inputField {
  flex: 1;
  width: 0;
}