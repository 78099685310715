.loadingSwitch {
  width: 40px;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
}
.themedSwitch {
  &.disabled {
    cursor: not-allowed;
    * {
      cursor: inherit !important;
    }
  }
}