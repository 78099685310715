@import '../../../../../scss/base/var';

.header {
  display: flex;
}

.icon {
  margin-right: 5px;
}

.textInputLine {
  .textInputLineHeader {
    display: flex;
    gap: 5px;
    padding: 0 0 6px 0;

    .textInputLineLabel {
      color: $color-text-light;
      font-size: 14px;
      display: block;
      white-space: nowrap;
      padding: 0; // overwrite
    }

    .textInputLineDescription {
      color: $color-text-light;
      flex-shrink: 0;
    }

    .textInputLineError {
      margin-left: auto;
      font-size: 14px;
      text-align: right;
      color: $color-red;
      float: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .textInputLineInput {
    width: 100%;
    border-radius: 3px;
    padding-right: 12px; // Default minimal padding when no dropdown

    &.success {
      border-color: $color-green;
    }

    &.error {
      border-color: $color-red;
    }

    // Only add extra padding when dropdown is present
    &.withDropdown {
      padding-right: 36px; // Space for dropdown icon
    }
  }
}

.inputContainer {
  position: relative;
  width: 100%;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.inputIcons {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

.successIcon {
  display: flex;
  color: $color-green;
  margin-right: 8px;
  font-size: 18px;
  // Transparent background - can overlap with text
  pointer-events: none;

  // When dropdown is present, position to its left
  .withDropdown & {
    position: absolute;
    right: 28px; // Position to left of dropdown
  }
}

.dropdownToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: auto; // Make clickable
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  max-height: 200px;
  overflow-y: auto;
  margin-top: 2px;
}

.dropdownOption {
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }

  &.selected {
    background-color: #e6f7ff;
    font-weight: 500;
  }
}
