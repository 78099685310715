@import '../../../../scss/base/var';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  &:disabled {
    cursor: default;
    opacity: 0.25;
  }

  &:focus {
    outline: none;
  }
}

.link {
  text-decoration: none;
}

.label {
  min-width: 120px;
  font-size: 14px;
  font-weight: 300;

  span {
    display: block;
    white-space: nowrap;
  }
}

.noLabel {
  aspect-ratio : 1 / 1;
}

.busy {
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
}

.rounded {
  height: 36px;
  padding: 0 12px;
  background: none;
  border: none;
  border-radius: 20px !important;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
  color: $color-white;
  font-size: 13px;
  font-weight: 400;

  svg {
    stroke: $color-white;
    transition: stroke 0.25s ease-in-out;
  }

  &:disabled {
    cursor: default;
    opacity: 0.25;
  }

  &.colorPrimary {
    background-color: $color-primary;

    &:enabled:not(.busy):not(.disableHover):not(.disableHover):hover {
      background-color: $color-primary-lighter30;
    }
  }

  &.colorSecondary {
    background-color: $color-secondary;

    &:enabled:not(.busy):not(.disableHover):hover {
      background-color: $color-secondary-darker30;
    }
  }

  &.colorPrimaryHighlight {
    background-color: $color-primary-highlight;

    &:enabled:not(.busy):not(.disableHover):hover {
      background-color: $color-primary-highlight-darker30;
    }
  }

  &.colorBlue {
    background-color: $color-blue;

    &:enabled:not(.busy):not(.disableHover):hover {
      background-color: lighten($color-blue, 20%);
    }
  }

  &.colorGreen {
    background-color: $color-green;

    &:enabled:not(.busy):not(.disableHover):hover {
      background-color: darken($color-green, 20%);
    }
  }

  &.colorOrange {
    background-color: $color-orange;

    &:enabled:not(.busy):not(.disableHover):hover {
      background-color: darken($color-orange, 20%);
    }
  }

  &.colorRed {
    background-color: $color-red;

    &:enabled:not(.busy):not(.disableHover):hover {
      background-color: darken($color-red, 20%);
    }
  }

  &.colorWhite {
    background-color: $color-white;
    color: $color-primary;

    svg {
      stroke: $color-primary;
    }

    &:enabled:not(.busy):not(.disableHover):hover {
      background-color: $color-primary;
      color: $color-white;

      svg {
        stroke: $color-white;
      }
    }
  }

  &.colorTransparent {
    background: none;
    color: $color-primary;
    text-decoration: none;
    box-shadow: none;

    svg {
      stroke: $color-primary;
    }

    &:enabled:not(.busy):not(.disableHover):hover {
      background-color: $color-primary;
      color: $color-white;

      svg {
        stroke: $color-white;
      }
    }
  }
}

.squared {
  height: 30px;
  border-radius: 2px;
  color: $color-text;
  // Faster transition looks better for the small icon
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  cursor: pointer;

  &:not(.disableHover):hover,
  &.toggled {
    background-color: $color-grey;
    color: $color-white;
  }

  &.disabled {
    color: $color-grey;

    &:not(.disableHover):hover {
      background-color: $color-white;
      color: $color-grey;
    }
  }
}

.tab {
  height: 42px;
  font-size: 14px;
  padding: 11px 20px;
  border-radius: 8px 8px 0 0;
  margin-top: 5px;
  margin-right: 5px;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;

  &:not(.disableHover):hover,
  &.toggled {
    color: $color-white;
    background-color: $color-primary;

    .TabLineBubbles--icon {
      color: $color-white;
    }
  }

  &:disabled {
    opacity: 0.4;
    text-decoration: none !important;
  }

  &:not(.toggled) {
    color: $color-text;
    background-color: $color-light-grey;

    svg {
      stroke: $color-text;
      transition: stroke 0.25s ease-in-out;
    }

    &:not(.disableHover):hover:not(:disabled) {
      color: $color-white;
      background-color: $color-primary;

      svg {
        stroke: $color-white;
      }
    }
  }
}