@use "../../../../../scss/base/theme.module";
@import '../../../../../scss/base/var';

:export {
  colorText: $color-text;
  colorTextLight: $color-text-light;
  colorLightGrey: $color-light-grey;
  colorRedDark: $color-red-dark;
}

.dropdownSelectInput {

  .header {
    display: flex;

    .label {
      color: $color-text-light;
      font-size: 14px;
      display: block;
      padding: 0 0 6px 0;
      white-space: nowrap;
      cursor: default;
    }

    .error {
      margin-left: auto;
      font-size: 14px;
      text-align: right;
      color: $color-red;
      float: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 0 0 5px;
      cursor: default;
    }
  }

  // The styles for react-select itself are located in DropdownSelectInput.tsx
}

.defaultOptionComponent {
  background-color: transparent;
  color: $color-text;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  box-sizing: border-box;
  position: relative;

  &.isSelected {
    background: theme.$color-secondary-lighter90;

    &:after {
      content: "\e904";
      font-family: 'dashboardNG';
      position: absolute;
      font-size: 18px;
      right: 10px;
      top: 5px;
      line-height: 23px;
      color: theme.$color-secondary;
    }
  }

  &.isFocused:not(.isSelected) {
    background-color: $color-light-grey;
  }

  &:hover {
    background-color: $color-light-grey;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $color-grey;
  }

  &:last-child{
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}
