@import '../../../../../scss/base/var.scss';

.audioRecorder {
  width: 100%;
  display: flex;

  .canvasContainer {
    flex: 1;
    width: 1px;
    display: flex;
    flex-direction: row;
    margin-left: 5px;
    margin-top: 2px;
    margin-bottom: 2px;
    overflow: hidden;

    .placeholderContainer {
      width: 100%;
      height: 100%;
      margin-left: 16px;
      display: flex;
      align-items: center;

      .placeholder {
        font-size: 15px;
        color: $color-text-light;
      }
    }
  }

  .waveformCanvas {
    margin: 0 10px;
    flex: 1;
    width: 1px; // flex-grow will make it grow to the full width
  }

  .recordButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: $color-light-grey;
    transition: background-color ease-in-out 0.1s;

    &:hover {
      background-color: $color-grey;
    }

    svg {
      color: $color-text-grey;
    }
  }
}
