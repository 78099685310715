@import '../../../../scss/base/var';

.configPaneContentContainer {
  height: 100%;
  overflow: auto;
}

.modalTextArea {
  width: 50vw;
  height: 50vh;
}

.showFileContainer {
  display: flex;
  align-items: center;
  margin: 4px 2px 12px 2px;

  &:hover {
    cursor: pointer;

    > span {
      text-decoration: underline;
    }
  }
}

.showFileIcon {
  margin-right: 7px;
}

.showFileText {
  font-size: small;
}

.textInputArea {
  margin-bottom: 20px;
}

.errorMessage {
  color: red;
  margin-top: 10px;
}

.editConfigContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  background-color: $color-white;
}

.interactionDisabled {
  cursor: not-allowed;
}

.elementTypeContainer {
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  gap: 6px;
  align-items: center;
  margin-bottom: 20px;

  .infoIcon {
    margin-right: 2px;
  }

  .infoIcon:hover {
    cursor: pointer;
  }
}

.verticalConfigLayout {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.lightText {
  color: $color-text-light;
  font-size: 14px;
  padding: 0 0 6px 0;
}