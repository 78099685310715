@import '../../../../scss/base/var';

.container {
  background-color: white;
  border-radius: 4px;
  padding: 40px 20px;
  height: 100%;
  overflow-y: auto;
}

.innerContainer {
  max-width: 900px;
  margin: 0 auto;
}

.headline {
  font-size: 20px;
  display: block;
  margin-bottom: 20px;
}

.description {
  margin-bottom: 40px;
}

.buttons {
  border-top: 1px solid $color-grey;
  padding-top: 20px;
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: flex-end;
}