
.draggableNode {
  position: relative;
  display: flex;
  height: 40px;
  width: 40px;
  margin: 8px 0 40px 0;
}

.nodeGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  justify-items: center;
  align-items: center;
}

.searchContainer {
  margin-bottom: 8px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
