.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 16px;
}

.title {
  font-size: 14px;
  font-weight: 400;
}

.description {
  font-size: 13px;
  color: lightgray;
}
