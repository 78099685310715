@import "../../../../../../../scss/base/var.module";

$color-any: #424750;
$color-boolean: #0052CC;
$color-number: #FF8C00;
$color-string: #36B37E;
$color-table: #9370DB;
$color-list: #D1D5DB;

.any {
  background-color: $color-any !important;
}

.boolean {
  background-color: $color-boolean !important;
}

.number {
  background-color: $color-number !important;
}

.string {
  background-color: $color-string !important;
}

.table {
  background-color: $color-table !important;
}

.list {
  background-color: $color-table !important;
}

.defaultNodeStyle {
  height: 40px;
  width: 40px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  border: #bbb solid 1px !important;
  background-color: #f8f8f8 !important;
  border-radius: 4px;
  color: $color-text-grey;

  &.clickable:hover {
    background-color: $color-grey;
    color: $color-white;
    cursor: pointer;
  }
}

.defaultHandleStyle {
  height: 8px;
  width: 4px;

  &.primitive {
    &.target {
      border-radius: 8px 0 0 8px;
    }

    &.source {
      border-radius: 0 8px 8px 0;
    }
  }

  &.list {
    border-top: #30333A solid 1px;
    border-bottom: #30333A solid 1px;

    &.target {
      border-left: #30333A solid 1px;
    }

    &.source {
      border-right: #30333A solid 1px;
    }
  }

  &.target {
    left: -2px;
  }

  &.source {
    right: -2px;
  }
}

.running {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    color: $color-text;
  }
  50% {
    color: $color-blue;
  }
  100% {
    color: $color-text;
  }
}

.nodeResultPreviewTraceback {
  height: 100%;
  overflow: auto;
  // cribbed from .output-container-parent.has-error in notebook.scss
  background-color: $color-red-light;
  // cribbed from .CodeCellOutputs--container-error in styles_common.scss
  color: $color-red;
}

.nodeContainer {
  position: relative;

  &:hover {
    .hoverButtonsContainer {
      opacity: 1;
    }
  }
}

.hoverButtonsContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  gap: 4px;
  top: -16px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: 10;
}

.nodeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  color: #444;

  &:hover {
    background-color: #f0f0f0;
    transform: scale(1.05);
  }
}

.status {
  position: absolute;
  height: 3px;
  bottom: 1px;
  width: calc(100% - 2px);
  border-radius: 0 0 6px 6px;
}

.name {
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  color: $color-text-light;
}
