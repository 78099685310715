@import '../../../../../scss/base/var';

.container {
  margin: 0 0 20px 0;
}

.title {
  font-size: 15px;
  font-weight: 400;
  color: #a6aeb5;
  margin: 20px 0;
}

.content {
  display: flex;
}

.userInfo {
  margin-right: 20px;
  width: 100%;
}

.line {
  margin-bottom: 16px;
  display: flex;
}

.column {
  display: block;
  margin-right: 20px;
}

.label {
  color: #a6aeb5;
  font-size: 14px;
  display: block;
  padding: 0 0 6px 0;
}

.value {
  color: $color-text;
  font-size: 18px;
}
