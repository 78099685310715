@import '../../../../scss/base/var';

.modalHeader {
  .modalHeadline {
    margin-right: 30px;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
    cursor: default;
  }

  .modalClose {
    width: 24px;
    height: 24px;
    text-align: center;
    font-size: 18px;
    line-height: 20px;
    font-weight: bold;
    color: $color-text;
    position: absolute;
    cursor: pointer;
    right: 6px;
    top: 6px;
    border-radius: 2px;
    transition: background-color 0.1s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $color-grey;
    }
  }
}

.modalBody {
  flex-grow: 1;
  overflow-y: auto;
}

.modalButtons {
  margin-top: 20px;
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: flex-end;
}
