.notifications {
  position: fixed;
  padding: 0 10px 10px;
  z-index: 997;
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}

.notification {
  width: 320px;
  position: relative;
  cursor: pointer;
  border-radius: 2px;
  margin: 10px 0 0;
  padding: 10px;
  display: block;
  box-sizing: border-box;
  opacity: 1;
  transition: 0.3s ease-in-out;
  right: 0;
  min-height: 60px;
  z-index:250
}

.notification__description {
  word-wrap: break-word;
}

.notification__progress-container {
  position: relative;
  margin-top: 15px;
  padding-bottom: 8px;

  .notification__progress-back {
    position: absolute;
    width: 100%;
    height: 13px;
    background-color: #ddd;
    border-radius: 5px;
  }

  .notification__progress-track {
    position: absolute;
    height: 13px;
    background-color: #87c333;
    border-radius: 5px;
  }

  .notification__progress-state {
    position: absolute;
    color: #000;
    z-index: 1;
    font-size: 11px;
  }
}

.notification--red {
  border-top: 2px solid rgb(236, 61, 61);
  color: rgb(65, 47, 47);
  box-shadow: rgba(236, 61, 61, 0.901961) 0 0 1px;
  background-color: rgb(244, 233, 233);
}

.notification--green {
  border-top: 2px solid rgb(94, 164, 0);
  color: rgb(75, 88, 58);
  box-shadow: rgba(94, 164, 0, 0.901961) 0 0 1px;
  background-color: rgb(240, 245, 234);
}

.notification__title {
  font-size: 14px;
  margin: 0 0 5px;
  padding: 0;
  font-weight: bold;
}

.notification__title--red {
  color: rgb(236, 61, 61);
}

.notification__title--green {
  color: rgb(94, 164, 0);
}

.notification__close {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  position: absolute;
  top: 4px;
  right: 5px;
  line-height: 15px;
  color: rgb(244, 233, 233);
  border-radius: 50%;
  width: 14px;
  height: 14px;
  font-weight: bold;
  text-align: center;
  background-color: rgb(228, 190, 190);
}
