.changelog {
  padding: 10px;

  h2 {
    // Same as the reset <p>
    margin-bottom: 14px;
  }

  h2:not(:first-child) {
    margin-top: 20px;
  }
}
