@import '../../../../../scss/base/var';

.container {
  margin: 0 0 60px 0;
}

.line {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between; // Distribute the sidenotes evenly
  flex-wrap: wrap;
}

.column {
  flex-shrink: 0;
  display: block;
  margin-right: 20px;
  margin-bottom: 20px;
}

.label {
  color: #a6aeb5;
  font-size: 14px;
  display: block;
  padding: 0 0 6px 0;
}

.value {
  color: $color-text;
  font-size: 14px;
  cursor: default;
}