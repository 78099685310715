@use "../../scss/base/theme.module";

.augurs {
  @include grid-row();
  @include grid-layout(2, ".augurs--col");
  @include breakpoint(small down) {
    @include grid-layout(1, ".augurs--col");
  }
  @include breakpoint(large up) {
    @include grid-layout(4, ".augurs--col");
  }

  max-width: 100%;
}

.augurs--col {
  @include grid-column(12, 20px);
  @include grid-column-end;
}

.augurs--item {
  word-wrap: break-word;
  word-break: break-all;
  background: $color-light-grey;
  margin: 0 0 20px 0;
  height: 130px;
  position: relative;
  border-radius: 2px;
  border: 1px solid $color-grey;

  .augurs--item--progress-label {
    top: -3px;
    position: relative;
    color: theme.$color-secondary-darker30;
    font-size: 0.9em;
  }

  &.health-0 {
    border-color: rgba($color-red, .4);

    .augurs--item--content {
      &:after {
        background: $color-red;
      }
    }

    .augurs--item--settings {
      .icon-alert {
        display: inline-block;
      }
    }

    .augurs--item--title {
      color: $color-red;
    }

    .augurs--item--events {
      color: $color-red;

      a, .augurs--item--events--link {
        color: $color-red;

        &:hover, &:active, &:focus {
          color: $color-red;
        }
      }
    }

    .augurs--item--text {
      color: $color-red;
    }
  }

  &.health-null {
    .augurs--item--content {
      &:after {
        background: darken($color-text-light, 20%);
      }
    }

    .augurs--item--settings {
      .float-right {
        .icon-refresh {
          display: inline-block;
        }
      }
    }

    .augurs--item--events {
      a, .augurs--item--events--link {
        color: $color-text-light;

        &:hover, &:active, &:focus {
          color: $color-text-light;
        }
      }
    }
  }

  &.health-1 {
    .augurs--item--content {
      &:after {
        background: darken($color-orange, 20%);
      }
    }

    .augurs--item--settings {
      .float-right {
        .icon-refresh {
          display: inline-block;
        }
      }
    }

    .augurs--item--events {
      a, .augurs--item--events--link {
        color: $color-orange;

        &:hover, &:active, &:focus {
          color: $color-orange;
        }
      }
    }
  }

  &.health-2 {
    .augurs--item--content {
      &:after {
        background: darken($color-yellow, 20%);
      }
    }

    .augurs--item--settings {
      .float-right {
        .icon-refresh {
          display: inline-block;
        }
      }
    }

    .augurs--item--events {
      a, .augurs--item--events--link {
        color: $color-yellow;

        &:hover, &:active, &:focus {
          color: $color-yellow;
        }
      }
    }
  }

  &.health-3 {
    .augurs--item--content {
      &:after {
        background: darken($color-lime, 20%);
      }
    }

    .augurs--item--settings {
      .float-right {
        .icon-refresh {
          display: inline-block;
        }
      }
    }

    .augurs--item--events {
      a, .augurs--item--events--link {
        color: $color-lime;

        &:hover, &:active, &:focus {
          color: $color-lime;
        }
      }
    }
  }

  &.health-4 {
    .augurs--item--content {
      &:after {
        background: darken($color-green, 20%);
      }
    }

    .augurs--item--settings {
      .float-right {
        .icon-refresh {
          display: inline-block;
        }
      }
    }

    .augurs--item--events {
      a, .augurs--item--events--link {
        color: $color-green;

        &:hover, &:active, &:focus {
          color: $color-green;
        }
      }
    }
  }

  .error-tooltip {
    display: none;
  }

  &:hover .error-tooltip {
    display: block;
  }
}

.augurs--item--title {
  margin: 0 45px 1px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 20px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-transform: none;
}

.augurs--item--title__link {
  color: $color-text;
  text-decoration: none;
  display: block;

  &:hover {
    text-decoration: underline;
  }
}

.augurs--item--title--sub {
  color: $color-text-light;
}

.augurs--item--content {
  position: relative;
  padding: 10px 12px 11px 25px;
  height: 100%;
  display: table;
  width: 100%;
  font-size: 12px;
  text-decoration: none;

  &:hover {
    .augurs--item--title {
      text-decoration: underline;
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: -1px;
    top: -1px;
    bottom: -1px;
    width: 4px;
    border-radius: 2px;
  }
}

.augurs--item--content {
  &:after {
    background: $color-orange;
  }
}

.augurs--item--info__ul {
  padding: 0 0 4px 0;
}

.augurs--item--info__li {
  padding: 0;
}

.augurs--item--info, .augurs--item--events {
  display: table-row;
  height: 40%;
}

.augurs--item--info__cell, .augurs--item--events__cell, .augurs--item--title__cell {
  display: table-cell;
}

.augurs--item--info--title__cell {
  vertical-align: top;
}

.augurs--item--info__cell {
  vertical-align: middle;
}

.augurs--item--events__cell {
  vertical-align: bottom;
  padding: 0 55px 0 0;

  .augurs--item--settings {
    display: none;
  }
}

.augurs--item--info__label {
  color: $color-text-light;
  padding: 0 3px 0 0;
}

.augurs--item--actions {
  position: absolute;
  right: 12px;
  top: 13px;
}

.augurs--item--settings {
  color: $color-text;
  text-align: right;
  margin: 0;

  a {
    text-decoration: none;
  }

  [class^="icon-"], [class*=" icon-"] {
    font-size: 19px;
    margin-left: 1px;
    text-decoration: none;
    color: $color-text-light;

    &:hover {
      color: $color-text;
    }
  }

  .settings--link {
    float: right;
    display: inline-block;

    .icon-settings {
      display: inline-block;
    }
  }

  .icon-refresh {
    &, &:hover {
      color: theme.$color-secondary;
    }
  }

  .icon-alert {
    &, &:hover {
      color: $color-red;
    }
  }

  .icon-play {
    color: theme.$color-primary;
  }
}

.augurs--item--text {
  padding: 2px 0 0 0;
  font-size: 12px;
}

.overlap {
  max-width: 218px;
  @media (min-width: 400px) {
    max-width: 297px;
  }
  @media (min-width: 624px) {
    max-width: 320px;
  }
  @media (min-width: 1024px) {
    max-width: 185px;
  }
  @media (min-width: 1200px) {
    max-width: 228px;
  }
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
  display: inline-block;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.augurs--add {
  color: $color-text-light;

  .augurs--item {
    display: block;
    color: $color-text-light;
    text-decoration: none;
  }

  .augurs--item--info__cell {
    text-align: center;
    vertical-align: middle;
  }

  .augurs--item--content {
    padding: 0;

    &:after {
      display: none;
    }
  }
}

.error-tooltip {
  position: absolute;
  height: 126px;
  background: $color-light-grey;
  z-index: 1000;
  font-size: 12px;
  padding: 7px 10px 6px 15px;
  word-break: normal;
  word-wrap: normal;
  box-shadow: none;
  border: none;
  overflow: hidden;

  .augurs--item--info__ul {
    padding: 0 0 7px 0;
    font-size: 11px;
  }

  .augurs--item--info__li {
    padding: 0 0 2px 0;
  }

  .augurs--item--info__label {
    min-width: 55px;
    display: inline-block;
  }

  .alert {
    font-size: 11px;
    font-weight: 400;
    padding: 0 0 8px 0;
  }

  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &.inline-tooltip {
    position: absolute;
    overflow: visible;
    left: 0;
    top: 0;
    margin-top: 130px;
    background: $color-white;
    border-radius: 2px;
    z-index: 1000;
    font-size: 12px;
    padding: 13px 20px 16px 20px;
    box-shadow: 0 1px 3px 0 #7d9dc2;
    word-break: normal;
    word-wrap: normal;

    &:before {
      content: "";
      position: absolute;
      left: 23px;
      top: -13px;
      border-width: 6px;
      border-style: solid;
      border-color: transparent;
      border-bottom-color: #7d9dc2;
      opacity: .4;
    }

    &:after {
      content: "";
      position: absolute;
      left: 23px;
      top: -12px;
      border-width: 6px;
      border-style: solid;
      border-color: transparent;
      border-bottom-color: $color-white;
    }
  }
}

.augurs--archive {
  .augurs--item {
    border-color: $color-grey;

    .augurs--item--content {
      color: $color-text-light;

      &:after {
        background: $color-text-light;
      }
    }

    .augurs--item--settings {
      .icon-play, .icon-refresh {
        color: theme.$color-primary;
      }
    }

    .augurs--item--title {
      color: $color-text-light;
    }

    .augurs--item--events {
      color: $color-text-light;

      a, .augurs--item--events--link {
        color: $color-text-light;

        &:hover, &:active, &:focus {
          color: $color-text-light;
        }
      }
    }

    .augurs--item--text {
      color: $color-text-light;
    }
  }
}

.switch-text {
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  margin-left: 10px;
}

.augur-icon {
  display: none;
  position: absolute;
  right: 2px;
  bottom: 2px;
  font-size: 50px;
  color: $color-grey;
}

.augurs {
  margin: 20px;
}

.code-capsules {
  margin: 20px;

  &.custom-table {
    .code-capsule-table-link {
      text-decoration: none;
    }
  }

  .ct-col {
    cursor: default;
  }

  .code-capsule-button-list {
    display: flex;

    .code-capsule-li {
      flex: none;
      margin-left: 5px;

      &:first-child {
        margin: 0 0 0 auto;
      }

      .code-capsule-run-now {
        display: flex;
        cursor: pointer;

        &:hover {
          .button-text {
            color: theme.$color-primary;
            text-decoration: underline;
          }
        }

        .icon-play {
          color: theme.$color-primary;
          margin: 2px 5px;
        }
      }
    }
  }
}
