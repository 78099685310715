.AdminUserDetails {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;

  .AdminUserDetails--header {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .AdminUserDetails--tabs-line {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .AdminUserDetails--tabs-content {
    flex-grow: 1;
    margin-top: 20px;
    overflow-y: auto;
  }

  .AdminUserDetails--part {
    margin-bottom: 30px;
    cursor: default;

    .AdminUserDetails--part-headline {
      font-size: 18px;
      font-weight: 400;
      display: block;
      margin-bottom: 10px;
    }

    .AdminUserDetails--part-description {
      display: block;
      margin-top: -5px;
      margin-bottom: 10px;
    }

    .AdminUserDetails--part-details-container {
      .AdminUserDetails--part-details-line {
        margin-bottom: 5px;
        .AdminUserDetails--part-details-key {
          font-weight: 400;
        }
        .AdminUserDetails--part-details-value {

        }
      }
    }

    .groups-container,
    .roles-container,
    .attributes-container {
      cursor: default;
      .groups-row,
      .roles-row,
      .attributes-row {
        display: flex;
        align-items: center;
      }
    }

    .attributes-container {
      .icon-container {
        align-self: center;
      }
    }

    .checkbox-item{
      width: 20px;
      height: 20px;
      margin: 0;
    }
  }
}
