@import '../../../../../../scss/base/var';

.modalContainer {
  display: flex;

  .bubble {
    margin-right: 20px;
    width: 40px;
    height: 40px;
    color: $color-white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 20px;
      padding: 0;
    }
  }

  .bubbleWarn {
    background-color: $color-orange;
  }

  .title {
    min-height: 40px;
    margin-bottom: 16px;
    font-weight: 500;
    color: $color-primary;
    display: flex;
    align-items: center;
    font-size: 15px;
    vertical-align: baseline;
  }
}
