@import '../../../../../../scss/base/var';

.modalCommitParent {
  min-width: 620px; // adjust later to be responsive
  max-width: 620px; // adjust later to be responsive
  padding-top: 24px; // Space for the X-icon

  .modalCommitButtons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spin {
  animation: spin 1s linear infinite;
}