@import '../../../../scss/base/var';

.notEvenOnePermission {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.fullViewHeight {
    padding-top: 110px; // to compensate for the header and sub-header
    height: 100vh;
  }

  .title {
    font-size: 18px;
    color: $color-text-grey;
    margin-bottom: 10px;
  }

  .description {
    font-size: 14px;
    color: $color-text-grey;
    margin-bottom: 40px;
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
