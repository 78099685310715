.collab-bar {
  display: flex;
  border-bottom: 1px solid #dce2eb;
  background-color: #fff;
  height: 41px;
}

.CollaborationSpace--content {
  padding: 20px 15px;
  height: 100%;
  overflow-y: auto;
  background-color: white;
  border-radius: 4px;

  .groups,
  .repositories {
    height: 100%;

    .headline {
      display: flex;
      flex-direction: row;
      padding: 0 10px 25px 10px;
      align-items: center;
      justify-content: flex-end;

      &.with-border {
        border-bottom: 1px solid $color-grey;
      }

      .search {
        margin-right: auto;
        margin-left: 40px;

        input {
          height: 36px;
          padding: 8px 13px;
          font-size: 14px;
        }
      }
    }

    .repository {
      display: flex;
      align-items: center;
      cursor: pointer;
      border-top: 1px solid $color-grey;
      padding: 10px;

      &:hover {
        background-color: $color-light-grey;
      }

      .repo-icon-parent {
        margin-right: 20px;
        color: #3a3b3d;
      }

      .repo-name-parent {
        flex-grow: 1;

        .repo-name {
          display: flex;
          align-items: center;

          .name {
            font-weight: 400;
            margin-right: 10px;
          }
        }

        .repo-description,
        .repo-created {
          color: $color-text-light;
          margin-top: 5px;
        }
      }
    }

    .group {
      display: flex;
      align-items: center;
      cursor: pointer;
      border-top: 1px solid $color-grey;
      padding: 10px;

      &:hover {
        background-color: $color-light-grey;
      }

      .group-icon-parent {
        margin-right: 20px;
      }

      .group-name-parent {
        flex-grow: 1;

        .group-name {
          .name {
            font-weight: 400;
          }
        }

        .group-description {
          color: $color-text-light;
          margin-top: 5px;
        }
      }
    }
  }
}

.branches {
  .branch {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-top: 1px solid $color-grey;
    padding: 10px;

    &:hover {
      background-color: $color-light-grey;
    }

    .branch-icon-parent {
      margin-right: 20px;
    }

    .branch-name-parent {
      flex-grow: 1;
      // The width is only required to make the flex-grow work properly. This fixed width will never occur but cause the
      //   text to grow to the maximum available width in the Row.
      width: 100px;

      .branch-name {
        .name {
          font-weight: 400;
        }
      }

      .branch-commit {
        color: $color-text-light;
        margin-top: 5px;

        // width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .branch-details {
        color: $color-text-light;
        margin-top: 5px;
        display: flex;
        align-items: center;
      }
    }

    .element-branch-buttons {
      display: flex;

      .element-branch-button {
        width: 30px;
        height: 30px;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-text;

        &:not(:last-child) {
          margin-right: 5px;
        }

        &:hover {
          background-color: $color-grey;
          color: $color-white;
        }
      }
    }
  }

  .headline {
    margin-right: auto;
    margin-bottom: 25px;
    display: flex;
    align-items: center;

    .search input {
      height: 36px;
      padding: 8px 13px;
      font-size: 14px;
    }

    .sorting-selector {
      margin-left: auto;
    }
  }
}

.ct-list {
  .ct-headline {
    background-color: transparent;
  }

  .ct-row {
    cursor: pointer;
  }

  .ct-col {
    align-self: center;

    .content-element-icon {
      color: #3a3b3d;
    }

    .ct-value span {
      line-height: 20px;
      vertical-align: center;
    }

    i {
      font-style: italic; // Stupid, but otherwise it's overwritten ...
    }
  }
}

.workbench-buttons {
  display: flex;
  padding: 5px 5px 5px 5px;
  height: 41px;

  .workbench-button {
    width: 30px;
    height: 30px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-text;
    margin-right: 5px;

    &:hover {
      background-color: $color-grey;
      color: $color-white;
    }

    &.disabled {
      color: $color-grey;

      &:hover {
        background-color: $color-white;
        color: $color-grey;
      }
    }
  }
}

.divider {
  width: 1px;
  background-color: $color-grey;
}

.repository { // TODO: Replace with styles.module.scss
  padding: 10px;

  .repository-top {
    display: flex;
    margin-top: 20px;

    .repository-info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      cursor: default;
      margin-bottom: 10px;

      .repository-top-line {
        margin-bottom: 10px;
        display: flex;

        .repository-name {
          font-size: 18px;
          font-weight: 400;
          margin-right: 20px;
        }

        .repository-item {
          margin-right: 10px;
        }
      }

      .repository-description {
        margin-bottom: 5px;
      }
    }

    .button-container {
      margin-left: 10px;

      > * {
        display: inline-block;
        margin-left: 8px;
      }
    }
  }
}


.build-code-capsule-wizard {
  .input-container {
    margin-top: 10px;

    &.input-notebooks-to-execute {
      margin-top: 20px;
    }

    .checkbox-parent {
      display: flex;

      .checkbox-item {
        display: inline-block;
        width: 20px;
      }
    }
  }

  .error-line {
    margin-top: 0;
  }
}

.repo-type {
  span {
    height: 20px;
    width: 120px;
    border-radius: 10px;
    color: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;

    &.plain {
      background-color: $color-text-light;
    }

    &.code-capsule {
      background-color: $color-blue;
    }

    &.app {
      background-color: $color-blue-highlight;
    }

    &.module {
      background-color: $color-green;
    }
  }
}

.add-repository-wizard {
  overflow-y: auto;
  height: 100%;

  .dialog-button-container {
    height: 100px; // if this isn't set, for some reason the buttons aren't visible when there's the need to scroll
    margin-bottom: 20px;
  }
}

.delete-repo-modal {
  width: 500px;

  .delete-repo-headline {
    font-size: 15px;
    font-weight: 500;
    color: $color-red;
  }

  .delete-repo-description {
    margin-top: 20px;
    white-space: pre-wrap;
  }

  .delete-repo-input {
    margin-top: 20px;
  }

  .delete-repo-button-bar {
    margin-top: 20px;
    display: flex;

    .button {
      display: inline-block;

      &:first-child {
        margin-left: auto;
      }

      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }

  .button-busy {
    height: 24px !important;
    margin-top: -24px;
  }
}

.MainContainer.CollaborationSpace {

  .add-repository-wizard {
    overflow-y: auto;
    height: 100%;
    border-radius: 4px !important;
    padding: 40px 100px 20px 100px !important;

    .dialog-button-container {
      margin-bottom: 20px;
      border-top: 1px solid $color-grey;
      margin-top: 60px;
      padding-top: 20px;
    }
  }

  .clone-repository-wizard {
    background-color: white !important;
    padding: 40px 100px 20px 100px !important;
    border-radius: 4px !important;
    height: 100%;
    overflow-y: auto;

    .dialog-button-container {
      margin-bottom: 20px;
      border-top: 1px solid $color-grey;
      margin-top: 60px;
      padding-top: 20px;
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
  }

  .clone-repository-wizard {
    .start-workbench-parent {
      display: flex;
      flex-direction: column;
      align-items: center;

      .buttons {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        gap: 8px;
      }
    }

    .container-content {
      border: 1px solid $color-grey;
      border-radius: 4px;

      .content-list {
        max-height: 400px;
      }
    }
  }

  .confirmation-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;

    .animation-container {
      margin: 0 0 40px 0;
    }
  }

  &.full-width {
    width: 100%;
    max-width: unset;
  }

  .existing-tags-parent {
    margin-top: 30px;

    .tags-headline {
      margin-bottom: 5px;
      font-weight: 400;
    }

    .tags-container {
      max-height: 300px;
      overflow-y: auto;
    }
  }
}

.top-side-by-side-parent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
