@import '../../../../../scss/base/var.scss';

.speech2Text {
  padding: 20px 15px 30px 15px;

  @media(max-width: $media-lg) {
    // This is necessary for iPhones to show the full input area on the bottom
    padding-bottom: 70px;
  }
  height: 100%;
  max-height: 100dvh; // https://stackoverflow.com/a/72245072
  position: relative;
  display: flex;
  flex-direction: column;

  .backButtonParent {
    margin-bottom: 20px;
  }

  .headlineBar {
    display: flex;

    .headline {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 20px;
      display: block;
    }

    .buttonLine {
      margin-left: auto;

      button {
        /* Otherwise the padding left of the icon is too large */
        padding: 0 12px 0 4px;
      }
    }
  }


  .toggleContainer {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-bottom: 10px;
    margin-right: 10px;

    .toggleLabel {
      margin-right: 5px;
    }
  }

  .recorderContainer {
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .outputContainer {
    margin-bottom: 10px;
    height: 110px;
    display: flex;
    flex-grow: 1;
  }

  .inputContainer {
    position: relative;
    display: flex;
    justify-content: center;
    height: 48px;
    border-radius: 24px;
    border: 1px solid $color-grey;
    background-color: white;
  }

  .infoContainer {
    margin: 5px 10px 0 10px;
    text-align: center;
    color: $color-text-grey;
    font-size: 12px;

    span {
      display: block;
    }
  }
}
