@import '../../../../../scss/base/var.scss';

.mainContainerInner {
  width: 100%;
}

.mainContainer {
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  display: flex;

  .sidebarContainer {
    border-right: 1px solid #dce2eb;
    height: 100%;
  }

  .tabContainer {
    height: 100%;
    flex-grow: 1;
  }
}

.vscodeIframe {
  border-radius: 4px;
}

.buttonsRight {
  display: flex;
  justify-content: flex-end;
  gap: 4px;

  &.vscode {
    position: absolute;
    top: 2px;
    left: 2px;
  }

  &.notebook { // TODO The placement of this  button is (still) hacky. The button will simply overlap the tabs of the opened files.
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.terminal {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background-color: $color-white;

  .actionButton {
    border-left: none;
  }

  .hotkeys {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .topBar {
    display: grid;
    border-bottom: 1px solid $color-grey;
    padding: 4px;
  }

  .aceEditor {
    flex-grow: 1;
  }
}

.jobLogs {
  overflow-y: auto;

  &.empty {
    color: $color-text-light;
  }

  .logEvent {
    display: block;
    font-family: 'Monaco', 'Courier New', monospace;
    font-size: 14px;

    .lineNumber {
      padding-right: 10px;
      min-width: 60px;
      text-align: right;
      background-color: #f6f6f6;
    }

    .logMessage {
      padding-left: 10px;
      word-wrap: break-word;
      overflow-wrap: anywhere;
    }
  }
}
