@import '../../../../scss/base/var.scss';

.overview {
  padding: 20px 15px;

  .habitatWithAssistants {
    margin-bottom: 30px;
    height: 100%;
    overflow-y: auto;

    .habitatName {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 10px;
      display: block;
    }

    .assistantsContainer {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
      grid-gap: 20px;

      .assistantCard {
        height: 130px;
        word-wrap: break-word;
        word-break: break-all;
        background-color: $color-light-grey;
        min-width: 265px;
        position: relative;
        border-radius: 2px;
        border: 1px solid $color-grey;

        > a {
          text-decoration: none;
          width: 100%;
          height: 100%;
          display: flex; // to ensure its growing the full card height

          &:hover {
            .augurName {
              text-decoration: underline;
            }
          }
        }

        &.disabled {
          .assistantCardInner {
            cursor: default;

            .augurName {
              color: $color-text-light;
            }
          }
        }

        .assistantCardInner {
          display: flex;
          flex-direction: column;
          padding: 10px 14px;
          height: 100%;

          .augurName {
            display: block;
            margin-bottom: 1px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            line-height: 20px;
            font-size: 13px;
            font-weight: 500;
          }

          .moduleName {
            display: block;
            color: $color-text-light;
            font-size: 12px;
          }

          .contentSpacer {
            flex-grow: 1;
          }

          .status {
            text-align: right;
            font-size: 12px;
            color: $color-text-light;
          }
        }
      }
    }
  }
}
