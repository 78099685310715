@import '../../../../scss/base/var.scss';

.ConfirmationModal{
  width: 400px;

  .ConfirmationModal--headline{
    font-size: 15px;
    font-weight: 500;
  }

  &--blue{
    color: $color-blue;
  }

  &--white{
    color: $color-white;
  }

  &--green{
    color: $color-green;
  }

  &--red{
    color: $color-red;
  }

  &--orange{
    color: $color-orange;
  }

  .ConfirmationModal--description {
    margin-top: 20px;
    white-space: pre-wrap;
  }

  .ConfirmationModal--input{
    margin-top: 20px;
  }

  .ConfirmationModal--button-bar {
    margin-top: 20px;
    display: flex;
    gap: 8px;
    flex-direction: row;
    justify-content: flex-end;
    border: none;
  }

  .button-busy {
    height: 24px !important;
    margin-top: -24px;
  }
}
