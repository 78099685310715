// This contains the styles for the addNotebook, addDirectory, reallySave, delete, ... dialogs
.dialog-container { // TODO DEPRECATED in favor for the Wizard-component (see js/components/pages/wizard)
  padding: 0 20px;

  .dialog-inner-container {
    max-width: 900px;
    margin: 20px auto 40px auto;

    .dialog-header {
      display: flex;
      align-items: flex-start;

      .dialog-header-item {
        margin-right: 20px;
      }
    }

    .dialog-headline {
      font-size: 20px;
      margin-bottom: 40px;
    }

    .step-container {
      color: $color-text;
      display: flex;
      margin-bottom: 40px;

      .bubble {
        margin-right: 20px;
        width: 40px;
        height: 40px;
        color: $color-white;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-size: 20px;
          padding: 0;
        }
      }

      .bubble-info {
        background-color: $color-primary;
      }

      .bubble-ok {
        background-color: $color-green;
      }

      .bubble-warn {
        background-color: $color-orange;
      }

      .bubble-delete {
        background-color: $color-red;
      }

      .checked-icon {
        color: $color-white;
      }

      .description-container {
        margin-top: 14px;
        display: flex;
        margin-right: 40px;

        .description {
          width: 300px;

          .title {
            min-height: 40px;
            margin-bottom: 16px;
            font-weight: 500;
            color: $color-primary;
            display: flex;
            align-items: center;
            font-size: 15px;
            vertical-align: baseline;
          }
        }
      }

      .input-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        input {
          border-radius: 5px;
          border: 1px solid $color-grey;
          height: 40px;
          font-size: 14px;
          font-family: $Roboto;
          color: $color-text;
          font-weight: 300;
          padding: 0 13px;
          margin: 0;
        }

        input:focus {
          outline: none !important;
        }

        .error-container {
          height: 18px;

          p {
            text-align: right;
            margin: 0;
            color: $color-red;
          }
        }
      }

      .input-container-error {
        .input, .textarea, .Select-control {
          border: 1px solid $color-red !important;
        }

        .path-input {
          color: $color-red;
        }
      }

      & .kernel-input {
        flex-direction: row;
      }
    }
  }

  .dialog-button-container {
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    .dialog-button {
      min-width: 120px;
      height: 40px;
      border: none;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 300;

      &:focus {
        outline: none;
      }

      &.dialog-button-neutral {
        background-color: $color-white;
        color: $color-primary;

        &:hover {
          background-color: $color-primary;
          color: $color-white;
        }
      }

      &.dialog-button-ok {
        color: $color-white;
        background-color: $color-green;

        &:disabled {
          opacity: 0.25;
        }
      }

      &.dialog-button-blue {
        color: $color-white;
        background-color: $color-blue;

        &:disabled {
          opacity: 0.25;
        }
      }

      &.dialog-button-warn {
        color: $color-white;
        background-color: $color-orange;

        &:disabled {
          opacity: 0.25;
        }
      }

      &.dialog-button-delete {
        color: $color-white;
        background-color: $color-red;

        &:disabled {
          opacity: 0.25;
        }

        &:hover {
          background-color: $color-red-dark;
        }

        display: flex;
        justify-content: center;
        align-items: center;

        .busy {
          margin-top: -12px;
        }
      }
    }
  }
}

// ---

.kernelspecs {
  .kernelspec-container {
    width: 260px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid $color-grey;
    border-radius: 5px;
    margin: 5px 0;
    cursor: pointer;
    overflow: hidden;
    transition: .2s ease-in-out;

    &:hover {
      background-color: $color-light-grey;
    }

    // kernel selected
    &.selected {
      border: 1px solid $color-primary-highlight;
      background-color: $color-light-grey;
    }

    // kernel unselected
    &.unselected {
      .icon-container {
        .icon {
          filter: saturate(0) brightness(1.1);
        }
      }
    }

    .icon-container {
      width: 40px;
      height: 40px;
      margin: 5px 10px;

      &.icon-container-other {
        padding: 8px;
        background-color: white;
        border-radius: 50%;
      }

      .icon {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .display-name {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
