@import '../../../../../scss/base/var.scss';

.chatMessagesOuter {
  width: 100%;
  height: 100%;
  position: relative;

  .fadeTop,
  .fadeBottom {
    position: absolute;
    left: 0;
    right: 0;
    height: 10px;
    pointer-events: none;
  }

  .fadeTop {
    top: 0;
    background: linear-gradient(to bottom, white, transparent);
  }

  .fadeBottom {
    bottom: 0;
    background: linear-gradient(to top, white, transparent);
  }
}

.chatMessages {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .message {
    // border: 1px solid deepskyblue;
    display: flex;
    margin-bottom: 12px;
    // border: 1px solid $color-grey;
    padding: 8px;
    border-radius: 10px;
    background-color: white;

    &.assistantMessage {
      .iconContainer {
        margin-right: 14px;
        background-color: $color-blue;
      }

      .textContainer {
        margin-left: 4px;
        margin-right: 80px;
      }
    }

    &.userMessage {
      .iconContainer {
        margin-left: 14px;
      }

      .textContainer {
        margin-right: 4px;
        margin-left: 80px;
      }

      .title, .content {
        text-align: right;
      }

      .loadingContainer {
        > div {
          margin-left: auto;
        }
      }
    }

    &.errorMessage {
      .content, .title {
        color: $color-red;
      }
    }

    .iconContainer {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }

    .textContainer {
      // border: 1px solid orange;
      width: 100%;
      padding-top: 4px; // To center the first line with the icon
      font-size: 15px;
      line-height: 20px;
      // border: 1px solid deeppink;

      .title {
        font-weight: 500;
        margin-bottom: 5px;
        display: block;
      }

      .content {
        display: block;
      }

      .loadingContainer {
        height: 30px;
      }
    }

    ul, ol {
      list-style: inside;
    }
  }
}

.sourceDocuments {
  .sourceDocumentsTitle {
    font-weight: 400;
    font-size: 14px;
    display: flex;
    margin-bottom: 5px;
    align-items: center;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .sourceDocumentsBody {
    font-size: 14px;

    .sourceDocument {
      &:not(:last-child) {
        margin-bottom: 10px;
      }

      border-left: 3px solid $color-grey;
      border-radius: 2px;
      background-color: $color-very-light-grey;
      padding: 5px 5px 5px 10px;

      .documentName {
        display: block;
        font-weight: 400;
      }

      .pageContent {

      }
    }
  }
}
