@import '../../../../../../../scss/base/var';

.parent{
  margin: 0;

  .fieldHeadline{
    display: flex;

    .label{
      color: $color-text-light;
      display: inherit;
      margin-bottom: 5px;
    }

    .errorLine{
      margin-top: 0;
      margin-left: auto;
    }
  }

  .input{
    &.invalid {
      border: 1px solid $color-red;
    }
  }
}