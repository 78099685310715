.Resources {
  height: 100%;

  .stopWorkbenchButton {
    margin-left: auto;
    display: inline-block;
    padding: 4px 12px; //Defines the height of the button
  }

  .parent {
    padding: 20px;
    height: 100%;
    overflow: auto;
  }
}
